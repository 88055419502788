import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CoeApi from "../apis/CoeApi";
import "../css/slides.css";
import "../css/loading.css";
import UpdateSection from "../components/UpdateSection";
const AddUpdateEquipmentsComponent = () => {
	const { labinitial } = useParams();

	const [labEquipmentslist, setLabEquipmentsList] = useState();

	const [dropdownmenuSelection, setdropdownmenuSelection] = useState("add");
	const [equipmentsTitle, setequipmentsTitle] = useState("");
	const [selectedequipmentsTitle, setselectedequipmentsTitle] = useState("");
	const [equipmentsGrouping, setequipmentsGrouping] = useState("");
	const [equipmentsWidth, setequipmentsWidth] = useState("");
	const [equipmentsEquipmentUrl, setequipmentsEquipmentUrl] = useState("");
	const [equipmentslinkList, setequipmentslinkList] = useState([]);
	const [updateEquipmentsLinkGrouping, setupdateEquipmentsLinkGrouping] =
		useState(() => {});

	const [equipmentsHeight, setequipmentsHeight] = useState("");

	const saveEquipmentInfo = async () => {
		try {
			const response = await CoeApi.post(`/labs/${labinitial}/equipments`, {
				title: equipmentsTitle,
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
				clearEverything();
			} else {
				alert("failed");
			}
		} catch (err) {
			console.log(err);
		}
	};

	const updateInfo = async () => {
		try {
			const response = await CoeApi.put(`/labs/${labinitial}/equipments`, {
				newTitle: equipmentsTitle,
				title: selectedequipmentsTitle,
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
			} else {
				alert("failed");
			}
		} catch (err) {
			console.log(err);
		}
	};

	const clearEverything = () => {
		setequipmentsTitle("");
	};

	const getSelectedUpdatePart = () => {
		if (dropdownmenuSelection === "add") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Add A Equipment",

							sectionTitle: "Equipment Title",
							sectionTitleValue: equipmentsTitle,
							setSectionTitleValue: setequipmentsTitle,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={saveEquipmentInfo}
						>
							Save
						</button>
					</div>
				</>
			);
		} else if (dropdownmenuSelection === "update") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Update A Equipment",

							distinguisher: "Title of the Equipment to be updated*",
							distinguisherlist: equipmentslinkList,
							distinguisherValue: selectedequipmentsTitle,
							setdistinguisherValue: setselectedequipmentsTitle,

							sectionTitle: "Equipment Title",
							sectionTitleValue: equipmentsTitle,
							setSectionTitleValue: setequipmentsTitle,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={updateInfo}
						>
							Update
						</button>
					</div>
				</>
			);
		}
	};

	useEffect(() => {
		if (
			updateEquipmentsLinkGrouping &&
			updateEquipmentsLinkGrouping[selectedequipmentsTitle]
		) {
			setequipmentsTitle(
				updateEquipmentsLinkGrouping[selectedequipmentsTitle][0].title
			);
		}
		return () => {};
	}, [selectedequipmentsTitle]);

	useEffect(() => {
		clearEverything();
		return () => {};
	}, [dropdownmenuSelection]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(`/labs/${labinitial}/labequipments`);

				setupdateEquipmentsLinkGrouping(response.data.LabEquipmentsGrouping);
				// console.log(response.data.LabEquipmentsGrouping)
				setequipmentslinkList([]);
				for (let id in response.data.LabEquipmentsGrouping) {
					setequipmentslinkList((equipmentslinkList) => [
						...equipmentslinkList,
						id,
					]);
				}
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<div>
			<section className="propub-main-c">
				<div className="propub-landing-c">
					<div className="propub-maintitle-c">
						<h1>Equipments</h1>
					</div>
				</div>

				<div className="Equipment-example-c">
					<h1>Equipment Example</h1>
					<img
						src="/Equipments/labEquipmentExample.png"
						alt=""
						className="Equipment-example"
					/>
				</div>

				<div className="update-part">
					<label htmlFor="landingMotto">What would you like to do?</label>

					<select
						id="rating"
						className="custom-select"
						value={dropdownmenuSelection}
						onChange={(e) => setdropdownmenuSelection(e.target.value)}
					>
						<option value="add">Add</option>
						<option value="update">Update</option>
					</select>

					{getSelectedUpdatePart()}
				</div>
			</section>
		</div>
	);
};

export default AddUpdateEquipmentsComponent;

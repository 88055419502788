import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";
import UpdateSection from "../components/UpdateSection";
import { useParams, useNavigate, Navigate } from "react-router-dom";

const UpdateLabsLandingComponent = (props) => {
	const { labname } = useParams();
	let navigate = useNavigate();

	const [updateMissionPartTitle, setupdateMissionPartTitle] = useState("");
	const [updateMissionPartDescription, setupdateMissionPartDescription] =
		useState("");

	const [labName, setLabName] = useState("");
	const [missionPart, setMissionPart] = useState("");

	const [updateLabLandingMedia, setupdateLabLandingMedia] = useState("");

	const [labFullName, setlabFullName] = useState("");
	const saveLandingChanges = async (mediaurl, missiondescription) => {
		try {
			const response = await CoeApi.put(`/labs/${props.labinitial}/labheader`, {
				description: missiondescription,
				mediaurl,
			});

			if (response.status === 200 || response.status === 201) {
				alert("success");
				// clearEverything();
				navigate("/");
				navigate(
					`/update/usercoeresearchpasswordeureka99/labs/${props.labinitial}`
				);
			} else if (response.status === 204) {
				alert(
					"Failed! Values not entered like they are supposed to. Please try again or contact your web developer for assistance."
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const clearEverything = () => {
		setupdateMissionPartDescription("");
		setupdateLabLandingMedia("");
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(`/labs/${props.labinitial}/header`);

				setupdateMissionPartDescription(
					response.data.LabHeaderRow.lablongintro
				);
				setupdateLabLandingMedia(response.data.LabHeaderRow.labcoverimagelink);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<div>
			<UpdateSection
				props={{
					method: "",
					sectionMediaTitle: "Picture For Your Lab's Landing Page",
					sectionMediaValue: updateLabLandingMedia,
					setSectionMediaLink: setupdateLabLandingMedia,

					sectionDescriptionTitle: "Lab's Long Info",
					sectionDescriptionValue: updateMissionPartDescription,
					setSectionDescriptionValue: setupdateMissionPartDescription,
				}}
			/>
			<div className="button-c">
				<button
					type="submit"
					className="btn btn-primary"
					onClick={() =>
						saveLandingChanges(
							updateLabLandingMedia,
							updateMissionPartDescription
						)
					}
				>
					Update
				</button>
			</div>
		</div>
	);
};

export default UpdateLabsLandingComponent;

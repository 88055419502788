import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CoeApi from "../apis/CoeApi";
import "../css/slides.css";
import "../css/loading.css";
import UpdateSection from "../components/UpdateSection";
const UpdateLabProjects = () => {
	const { labinitial } = useParams();

	const [labProjectslist, setLabProjectsList] = useState();

	const [dropdownmenuSelection, setdropdownmenuSelection] = useState("add");
	const [updateProjectTitle, setupdateProjectTitle] = useState("");
	const [selectedUpdateProjectTitle, setselectedUpdateProjectTitle] =
		useState("");
	const [updateProjectDescription, setupdateProjectDescription] = useState("");
	const [updateProjectGroups, setupdateProjectGroups] = useState("");
	const [updateProjectImageUrl, setupdateProjectImageUrl] = useState("");
	const [updateProjectTitleList, setupdateProjectTitleList] = useState([]);
	const [updateProjectsTitleGrouping, setupdateProjectsTitleGrouping] =
		useState(() => {});

	const [updateDetaillink, setupdateDetaillink] = useState("");

	const saveProjectInfo = async () => {
		try {
			const response = await CoeApi.post(`/labs/${labinitial}/projects`, {
				title: updateProjectTitle,
				description: updateProjectDescription,
				imagelink: updateProjectImageUrl,
				grouping: updateProjectGroups,
				detaillink: updateDetaillink,
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
				clearEverything();
			} else {
				alert("failed");
			}
		} catch (err) {
			console.log(err);
		}
	};

	const updateProjectInfo = async () => {
		try {
			const response = await CoeApi.put(`/labs/${labinitial}/projects`, {
				newTitle: updateProjectTitle,
				description: updateProjectDescription,
				imagelink: updateProjectImageUrl,
				grouping: updateProjectGroups,
				detaillink: updateDetaillink,
				title: selectedUpdateProjectTitle,
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
			} else {
				alert("failed");
			}
		} catch (err) {
			console.log(err);
		}
	};

	const clearEverything = () => {
		setupdateProjectTitle("");
		setupdateProjectDescription("");
		setupdateProjectImageUrl("");
		setupdateProjectGroups("");
		setupdateDetaillink("");
	};

	const getSelectedUpdatePart = () => {
		if (dropdownmenuSelection === "add") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Add A Project",

							sectionTitle: "Project Title",
							sectionTitleValue: updateProjectTitle,
							setSectionTitleValue: setupdateProjectTitle,

							sectionDescriptionTitle: "Project Description",
							sectionDescriptionValue: updateProjectDescription,
							setSectionDescriptionValue: setupdateProjectDescription,

							sectionMediaTitle: "Choose/Change Project Picture",
							sectionMediaValue: updateProjectImageUrl,
							setSectionMediaLink: setupdateProjectImageUrl,

							textInputSecondTitle:
								"Title of the group that this project belongs to*",
							textInputSecondValue: updateProjectGroups,
							settextInputSecondValue: setupdateProjectGroups,
							textInputSecondPlaceholder:
								"Current Biomedical Engineering Projects",

							detaillinkTitle: "Project Detail Link (If any)",
							detaillinkValue: updateDetaillink,
							setdetaillinkValue: setupdateDetaillink,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={saveProjectInfo}
						>
							Save
						</button>
					</div>
				</>
			);
		} else if (dropdownmenuSelection === "update") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Update A Project",

							distinguisher: "Title of the project to be updated*",
							distinguisherlist: updateProjectTitleList,
							distinguisherValue: selectedUpdateProjectTitle,
							setdistinguisherValue: setselectedUpdateProjectTitle,

							sectionTitle: "Project Title",
							sectionTitleValue: updateProjectTitle,
							setSectionTitleValue: setupdateProjectTitle,

							sectionDescriptionTitle: "Project Description",
							sectionDescriptionValue: updateProjectDescription,
							setSectionDescriptionValue: setupdateProjectDescription,

							sectionMediaTitle: "Choose/Change Project Picture",
							sectionMediaValue: updateProjectImageUrl,
							setSectionMediaLink: setupdateProjectImageUrl,

							textInputSecondTitle:
								"Title of the group that this project belongs to*",
							textInputSecondValue: updateProjectGroups,
							settextInputSecondValue: setupdateProjectGroups,
							textInputSecondPlaceholder:
								"Current Biomedical Engineering Projects",

							detaillinkTitle: "Project Detail Link (If any)",
							detaillinkValue: updateDetaillink,
							setdetaillinkValue: setupdateDetaillink,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={updateProjectInfo}
						>
							Update
						</button>
					</div>
				</>
			);
		}
	};

	useEffect(() => {
		if (
			updateProjectsTitleGrouping &&
			updateProjectsTitleGrouping[selectedUpdateProjectTitle]
		) {
			setupdateProjectTitle(
				updateProjectsTitleGrouping[selectedUpdateProjectTitle][0].title
			);
			setupdateProjectDescription(
				updateProjectsTitleGrouping[selectedUpdateProjectTitle][0].description
			);
			setupdateProjectImageUrl(
				updateProjectsTitleGrouping[selectedUpdateProjectTitle][0].imagelink
			);
			setupdateProjectGroups(
				updateProjectsTitleGrouping[selectedUpdateProjectTitle][0].grouping
			);
			setupdateDetaillink(
				updateProjectsTitleGrouping[selectedUpdateProjectTitle][0].detaillink
			);
		}
		return () => {};
	}, [selectedUpdateProjectTitle]);

	useEffect(() => {
		clearEverything();
		return () => {};
	}, [dropdownmenuSelection]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(`/labs/${labinitial}/projects`);

				setupdateProjectsTitleGrouping(response.data.LabProjectsTitleGrouping);
				console.log(response.data.LabProjectsTitleGrouping);
				setupdateProjectTitleList([]);
				for (let id in response.data.LabProjectsTitleGrouping) {
					setupdateProjectTitleList((updateProjectTitleList) => [
						...updateProjectTitleList,
						id,
					]);
				}
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<div>
			<section className="propub-main-c">
				<div className="propub-landing-c">
					<div className="propub-maintitle-c">
						<h1>Projects</h1>
					</div>
				</div>

				<div className="project-example-c">
					<h1>Project Example</h1>
					<img
						src="/images/labProjectExample.png"
						alt=""
						className="project-example"
					/>
				</div>

				<div className="update-part">
					<label htmlFor="landingMotto">What would you like to do?</label>

					<select
						id="rating"
						className="custom-select"
						value={dropdownmenuSelection}
						onChange={(e) => setdropdownmenuSelection(e.target.value)}
					>
						<option value="add">Add</option>
						<option value="update">Update</option>
					</select>

					{getSelectedUpdatePart()}
				</div>
			</section>
		</div>
	);
};

export default UpdateLabProjects;

import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";

const LabTitleAndImages = (props) => {
	const [LabTitleAndImages, setLabTitleAndImages] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(
					`/labs/${props.labinitial}/titleandimages`
				);
				setLabTitleAndImages(response.data.LabTitleAndImagesRows);
				// console.log(response.data.LabTitleAndImagesRows);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<>
			<div className="all-screenshots-c lab-pictures">
				{LabTitleAndImages &&
					Object.keys(LabTitleAndImages).map((key, i) => {
						return (
							<div key={key}>
								<div className="screenshots-title-c">
									<h2 className="">{key}</h2>
								</div>

								<div className="screenshots-lab-powerpoints-c">
									{LabTitleAndImages[key].map((eachImage) => {
										return (
											<div key={eachImage.id} className="screenshot-c">
												<img
													src={eachImage.imagelink}
													alt=""
													style={{
														maxWidth: eachImage.maxwidth
															? eachImage.maxwidth
															: "640px",
														maxHeight: eachImage.maxHeight
															? eachImage.maxHeight
															: "417px",
													}}
												/>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
			</div>
		</>
	);
};

export default LabTitleAndImages;

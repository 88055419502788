import React, { useContext, useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";
import LandingHeaderPart from "../components/LandingHeaderPart";
import LandingMission from "../components/LandingMission";
import LandingMission2 from "../components/LandingMission2";
import SetCoeLandingVariables from "../components/SetCoeLandingVariables";
import { CoeContext } from "../context/CoeContext";
import "../css/landing.css";
import "../css/genericUpdateStyle.css";

import UpdateTitleImageDesc from "./UpdateTitleImageDesc";

const UpdateHome = () => {
	const { landingVariables } = useContext(CoeContext);

	return (
		<>
			<SetCoeLandingVariables />
			{landingVariables ? (
				<>
					<LandingHeaderPart />
					<UpdateTitleImageDesc
						props={{
							rowname: "landingText",
							updateContainerName: "Update Landing Section",
							titleSectionName: "Landing Title",
							mediaSectionName: "Landing Image",
							descriptionSectionName: "Landing Description",
						}}
					/>
					<LandingMission />
					<UpdateTitleImageDesc
						props={{
							rowname: "missionStatement",
							updateContainerName: "Update Mission Section",
							titleSectionName: "Mission Title",
							mediaSectionName: "Mission Image",
							descriptionSectionName: "Mission Description",
						}}
					/>
					<LandingMission2 />
					<UpdateTitleImageDesc
						props={{
							rowname: "mission2",
							updateContainerName: "Update Above Section",
							titleSectionName: "Update Above Title",
							mediaSectionName: "Update Above Image",
							descriptionSectionName: "Update Above Description",
						}}
					/>
				</>
			) : (
				<h1>Loading... </h1>
			)}
		</>
	);
};

export default UpdateHome;

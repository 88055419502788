import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CoeApi from "../apis/CoeApi";
import ImageHandler from "../components/ImageHandler";
import { CoeContext } from "../context/CoeContext";

const UpdateTitleImageDesc = ({ props }) => {
	let navigate = useNavigate();

	const { landingVariables } = useContext(CoeContext);

	const [landingRow, setLandingRow] = useState();

	const onHandleChange = (e) =>
		setLandingRow({ ...landingRow, [e.target.name]: e.target.value });

	useEffect(() => {
		const fetchLandingInfo = async () => {
			try {
				setLandingRow(landingVariables[props.rowname][0]);
			} catch (err) {}
		};
		fetchLandingInfo();
	}, []);
	const handleSubmitForm = async (e) => {
		e.preventDefault();

		try {
			const response = await CoeApi.put(`/landingpage`, {
				landingRow,
			});
			if (response.status === 200 || response.status === 201) {
				alert("success");
				navigate("/");
				navigate(`/update/usercoeresearchpasswordeureka99/`);
			} else if (response.status === 204) {
				alert(
					"Failed! Values not entered like they are supposed to. Please try again or contact your web developer for assistance."
				);
			}
		} catch (err) {
			console.error(err.message);
		}
	};
	return (
		<>
			{landingRow && (
				<div className="generic-update-c">
					<h1 className="">{props.updateContainerName}</h1>
					<div>
						<h4 htmlFor="title">{props.titleSectionName}</h4>

						<div>
							<textarea
								type="text"
								name="title"
								value={landingRow.title}
								onChange={(e) => onHandleChange(e)}
							/>
						</div>

						<h4 htmlFor="description">{props.descriptionSectionName}</h4>
						<div>
							<textarea
								type="text"
								name="description"
								value={landingRow.description}
								onChange={(e) => onHandleChange(e)}
							/>
						</div>
                        
						<ImageHandler
							props={{
								sectionMediaTitle: props.mediaSectionName,
								sectionMediaValue: landingRow,
								setSectionMediaLink: setLandingRow,
							}}
						/>

						<button className="" onClick={handleSubmitForm}>
							Update
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default UpdateTitleImageDesc;

import React, { useContext, useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";
import { CoeContext } from "../context/CoeContext";
import "../css/landing.css";
// import Navigation from '../components/Navigation';

const SetCoeLandingVariables = () => {
	const { setLandingVariables } = useContext(CoeContext);

	useEffect(() => {
		const fetchLandingInfo = async () => {
			try {
				const result = await CoeApi.get(`/landingpage`);
				setLandingVariables(result.data.LandingRowsGrouping);
				console.log(result.data.LandingRowsGrouping)
			} catch (err) {}
		};
		fetchLandingInfo();
	}, []);

	return <></>;
};

export default SetCoeLandingVariables;

import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";

const LabTitleAndVideos = (props) => {
	const [LabTitleAndVideos, setLabTitleAndVideos] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(
					`/labs/${props.labinitial}/titleandvideos`
				);
				setLabTitleAndVideos(response.data.LabTitleAndVideosRows);
				console.log(response.data.LabTitleAndVideosRows);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<>
			<div className="all-screenshots-c lab-pictures">
				{LabTitleAndVideos &&
					Object.keys(LabTitleAndVideos).map((key, i) => {
						return (
							<div key={i}>
								<div className="screenshots-title-c">
									{key && <h2 className="">{key}</h2>}
								</div>

								<div className="screenshots-lab-powerpoints-c">
									{LabTitleAndVideos[key].map((eachVideo) => {
										return (
											<div key={eachVideo.id} className="screenshot-c">
												<video
													controls={true}
													src={eachVideo.videolink}
													style={{
														maxWidth: eachVideo.maxwidth
															? eachVideo.maxwidth
															: "640px",
														maxHeight: eachVideo.maxHeight
															? eachVideo.maxHeight
															: "417px",
													}}
												></video>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
			</div>
		</>
	);
};

export default LabTitleAndVideos;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CoeApi from "../apis/CoeApi";
import ImageHandler from "../components/ImageHandler";
import { CoeContext } from "../context/CoeContext";
import "../css/genericUpdateStyle.css";

const SpecificFacultyContactUpdate = () => {
	let navigate = useNavigate();
	const { id } = useParams();
	const [facultyContactObj, setFacultyContactObj] = useState();

	const [tempGrouping, setTempGrouping] = useState("");
	const [groupingList, setGroupingList] = useState(new Set());
	groupingList.add("Other");

	const { facultyContactListGrouping } = useContext(CoeContext);
	useEffect(() => {
		const fetchResult = async () => {
			
			try {
				if (facultyContactListGrouping) {
					Object.keys(facultyContactListGrouping).forEach((element) =>
						setGroupingList((prev) => new Set(prev.add(element)))
					);

				}
			} catch (err) {}
		};
		fetchResult();
	}, [facultyContactListGrouping]);
	
	useEffect(() => {
		if (tempGrouping !== "Other") {
			setFacultyContactObj({ ...facultyContactObj, "grouping": tempGrouping });
		}
	}, [tempGrouping]);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(`/facultycontact/${id}`);
				setFacultyContactObj(response.data.FacultyContactRow);
				setTempGrouping(response.data.FacultyContactRow.grouping);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);
	const getGroupingTextBox = () => {
		if (tempGrouping === "Other") {
			return (
				<>
					<h4>What's the grouping faculty belongs to?</h4>

					<textarea
						type="text"
						name="grouping"
						value={facultyContactObj.grouping}
						onChange={(e) => onHandleChange(e)}
					/>
				</>
			);
		}
	};

	const onHandleChange = (e) =>
		setFacultyContactObj({
			...facultyContactObj,
			[e.target.name]: e.target.value,
		});

	const handleSubmitForm = async (e) => {
		e.preventDefault();

		try {
			const facultyContactObject = JSON.stringify(facultyContactObj);
			const response = await CoeApi.put(`/facultycontact/${id}`, {
				facultyContactObject,
			});
			if (response.status === 200 || response.status === 201) {
				alert("success");
				navigate(`/update/usercoeresearchpasswordeureka99/facultycontact`);
			} else if (response.status === 204) {
				alert(
					"Failed! Values not entered like they are supposed to. Please try again or contact your web developer for assistance."
				);
			}
		} catch (err) {
			console.error(err.message);
		}
	};

	return (
		<>
			{facultyContactObj && (
				<div className="generic-update-c">
					<h1 className="">Update Faculty Contact</h1>
					<div>
						<h4 htmlFor="title">Faculty Contact Name</h4>

						<div>
							<textarea
								type="text"
								name="name"
								value={facultyContactObj.name}
								onChange={(e) => onHandleChange(e)}
							/>
						</div>

						<h4 htmlFor="description">Faculty Contact Email</h4>
						<div>
							<textarea
								type="text"
								name="email"
								value={facultyContactObj.email}
								onChange={(e) => onHandleChange(e)}
							/>
						</div>

						<ImageHandler
							props={{
								sectionMediaTitle: "Choose/Change keep exploring link Image",
								sectionMediaValue: facultyContactObj,
								setSectionMediaLink: setFacultyContactObj,
								columnName: "imagelink",
							}}
						/>

						<h4 htmlFor="title">Faculty Contact Detail link</h4>

						<div>
							<textarea
								type="text"
								name="detaillink"
								value={facultyContactObj.detaillink}
								onChange={(e) => onHandleChange(e)}
							/>
						</div>
						<h4 htmlFor="grouping">What's the grouping faculty belongs to?</h4>
						<div>
							<select
								value={tempGrouping}
								onChange={(e) => setTempGrouping(e.target.value)}
							>
								<option>Select</option>
								{groupingList &&
									Array.from(groupingList).map((eachoption) => {
										return (
											<option value={eachoption} key={eachoption}>
												{eachoption}
											</option>
										);
									})}
							</select>
						</div>
						{getGroupingTextBox()}

						<button className="" onClick={handleSubmitForm}>
							Update
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default SpecificFacultyContactUpdate;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CoeApi from "../apis/CoeApi";
import UpdateSection from "../components/UpdateSection";

const UpdateLabStudents = () => {
	const { labinitial } = useParams();
	let navigate = useNavigate();

	const [dropdownmenuSelection, setdropdownmenuSelection] = useState("add");

	const [teamMemberList, setteamMemberList] = useState();
	const [studentNameGrouping, setstudentNameGrouping] = useState(() => {});
	const [updatestudentNameList, setupdatestudentNameList] = useState([]);

	const [updateEmail, setupdateEmail] = useState("");
	const [updateNewName, setupdateNewName] = useState("");
	const [updateName, setupdateName] = useState(() => {
		return "";
	});
	const [workingFor, setworkingFor] = useState("");
	const [updatemedialink, setupdatemedialink] = useState("");
	const [updatepost, setupdatepost] = useState("");

	const [updateLabinitial, setupdateLabinitial] = useState("");

	const [labInitialsList, setlabInitialsList] = useState(() => {});

	const saveInfo = async () => {
		try {
			const response = await CoeApi.post(`/labs/${labinitial}/student`, {
				imagelink: updatemedialink,
				name: updateName,
				post: updatepost,
				email: updateEmail,
				workingfor: workingFor,
			});

			console.log(response);
			// navigate(`/`);
			// navigate(
			// 	`/addteammembers/userlabteampasswordchia987/${labinitial}`
			// );

			if (response.status === 200 || response.status === 201) {
				alert("success");
				clearEverything();
			} else if (response.status === 204) {
				alert(
					"Failed! Values not entered like they are supposed to. Please try again or contact your web developer for assistance."
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const updateInfo = async () => {
		try {
			const response = await CoeApi.put(`/labs/${labinitial}/student`, {
				imagelink: updatemedialink,
				name: updateName,
				post: updatepost,
				email: updateEmail,
				workingfor: workingFor,
				newName: updateNewName,
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
			} else if (response.status === 204) {
				alert(
					"Failed! Values not entered like they are supposed to. Please try again or contact your web developer for assistance."
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const clearEverything = () => {
		setupdateNewName("");
		setupdatemedialink("");
		setupdateName("");
		setworkingFor("");
		setupdateLabinitial("");
		setupdatepost("");
		setupdateEmail("");
	};

	useEffect(() => {
		clearEverything();
		return () => {};
	}, [dropdownmenuSelection == "update"]);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const studentResponse = await CoeApi.get(
					`/labs/${labinitial}/students`
				);
				console.log(studentResponse.data.LabStudentNameGrouping);
				setstudentNameGrouping(studentResponse.data.LabStudentNameGrouping);
				setupdatestudentNameList([]);
				for (let email in studentResponse.data.LabStudentNameGrouping) {
					setupdatestudentNameList((updatestudentNameList) => [
						...updatestudentNameList,
						email,
					]);
				}
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (studentNameGrouping && studentNameGrouping[updateName]) {
			setupdateNewName(studentNameGrouping[updateName][0].name);
			setworkingFor(studentNameGrouping[updateName][0].workingfor);
			setupdatemedialink(studentNameGrouping[updateName][0].imagelink);
			setupdatepost(studentNameGrouping[updateName][0].post);
			setupdateEmail(studentNameGrouping[updateName][0].email);
		}
		return () => {};
	}, [updateName]);

	const getSelectedUpdatePart = () => {
		if (dropdownmenuSelection === "add") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Add",

							email: "Student Email *",
							emailValue: updateEmail,
							setemailValue: setupdateEmail,
							emailPlaceholder:
								teamMemberList && "e.g. " + teamMemberList[0].email,

							sectionTitle: "Name",
							sectionTitleValue: updateName,
							setSectionTitleValue: setupdateName,

							sectionDescriptionTitle: "Professor/faculty working for",
							sectionDescriptionValue: workingFor,
							setSectionDescriptionValue: setworkingFor,
							sectionDescriptionPlaceholder: "Dr. Indic",

							sectionMediaTitle: "Picture",
							sectionMediaValue: updatemedialink,
							setSectionMediaLink: setupdatemedialink,

							distinguisherThird: "Choose Your Post In The Lab*",
							distinguisherThirdlist: [
								"graduate assistant",
								"undergraduate assistant",
								"alumni",
							],
							distinguisherThirdValue: updatepost,
							setdistinguisherThirdValue: setupdatepost,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={saveInfo}
						>
							Save
						</button>
					</div>
				</>
			);
		} else if (dropdownmenuSelection === "update") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Update",

							distinguisher: "Choose Your Name*",
							distinguisherlist: updatestudentNameList,
							distinguisherValue: updateName,
							setdistinguisherValue: setupdateName,

							newInfoTitle: "Student Name *",
							newInfoValue: updateNewName,
							setnewInfoValue: setupdateNewName,

							email: "Email",
							emailValue: updateEmail,
							setemailValue: setupdateEmail,

							sectionDescriptionTitle: "Professor/faculty working for",
							sectionDescriptionValue: workingFor,
							setSectionDescriptionValue: setworkingFor,
							sectionDescriptionPlaceholder: "Dr. Indic",

							sectionMediaTitle: "Picture",
							sectionMediaValue: updatemedialink,
							setSectionMediaLink: setupdatemedialink,

							distinguisherThird: "Choose Your Post In The Lab*",
							distinguisherThirdlist: [
								"graduate assistant",
								"undergraduate assistant",
								"alumni",
							],
							distinguisherThirdValue: updatepost,
							setdistinguisherThirdValue: setupdatepost,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={updateInfo}
						>
							Save
						</button>
					</div>
				</>
			);
		}
	};

	return (
		<section className="partners-main">
			<div className="partners-landing-container">
				<div className="landing-parts">
					<h2 className="partners-title h2-elements">Update Lab Team</h2>
				</div>
			</div>

			<div className="partners-listing" style={{ textAlign: "center" }}>
				<h1>Student Example</h1>
				<img
					src="/images/labMemberExample.png"
					alt=""
					style={{ width: "300px", height: "400px" }}
				/>
			</div>

			<div className="update-part">
				<label htmlFor="landingMotto">What would you like to do?</label>

				<select
					id="rating"
					className="custom-select"
					value={dropdownmenuSelection}
					onChange={(e) => setdropdownmenuSelection(e.target.value)}
				>
					<option value="add">Add</option>
					<option value="update">Update</option>
				</select>
				{getSelectedUpdatePart()}
			</div>
		</section>
	);
};

export default UpdateLabStudents;

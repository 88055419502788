import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";
import { useNavigate } from "react-router-dom";

const UpdateLabEquipments = (props) => {
	const [LabEquipments, setLabEquipments] = useState();
	let navigate = useNavigate();

	const myStyle = {
		color: "white",
		backgroundColor: "teal",
		marginLeft: "48px",
		padding: "8px 32px",
	};

	const goToUpdateEquipments = () => {
		navigate(
			`/add/userlabmemberpasswordcoemember234/labs/${props.labinitial}/labequipments`
		);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(
					`/labs/${props.labinitial}/labequipments`
				);
				setLabEquipments(response.data.LabEquipmentsRows);
				// console.log(response.data.LabEquipmentsRows);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return LabEquipments && LabEquipments.length > 0 ? (
		<div className="lab-equipments-c" style={{padding: "0"}}>
			<div className="lab-equipments-title-c">
				<h2 className="lab-equipments-title">
					{LabEquipments.length > 0 && "Equipments"}
				</h2>
			</div>

			{LabEquipments &&
				LabEquipments.map((eachEquipment) => {
					return (
						<h3 className="lab-equipment-name" key={eachEquipment.id}>
							{eachEquipment.title}
						</h3>
					);
				})}

			{LabEquipments && JSON.stringify(LabEquipments) !== "{}" && (
				<div className="button-c">
					<button style={myStyle} onClick={goToUpdateEquipments}>
						Add/Update Lab Equipments
					</button>
				</div>
			)}
		</div>
	) : (
		<div style={{ height: "0px" }}></div>
	);
};

export default UpdateLabEquipments;

import React, { useContext, useEffect } from "react";
import { CoeContext } from "../context/CoeContext";

const LandingHeaderPart = () => {
	const { landingVariables } = useContext(CoeContext);

	return (
		<>
			
			{landingVariables["landingText"] && (
				<div className="landing-heading-main-c">
					<div className="website-name-c">
						<h2 className="website-name">
							{landingVariables["landingText"][0].title}
						</h2>
					</div>

					<div className="landing-header-pictures-c">
						<div className="landing-header-picture1-c">
							<img src={landingVariables["landingText"][0].imagelink} alt="" />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LandingHeaderPart;

import React, { useState, createContext } from "react";

export const CoeContext = createContext();

export const CoeContextProvider = (props) => {
	// const [isChecked, setIsChecked] = useState(false);
	const [landingVariables, setLandingVariables] = useState();
	const [facultyContactList, setFacultyContactList] = useState([]);
	const [facultyContactListGrouping, setFacultyContactListGrouping] = useState(
		[]
	);

	// if (isChecked){
	// 	document.querySelector('.navigation ul').style.overflow = 'hidden';
	// }

	return (
		<CoeContext.Provider
			value={{
				landingVariables,
				setLandingVariables,
				facultyContactList,
				setFacultyContactList,
				facultyContactListGrouping,
				setFacultyContactListGrouping,
			}}
		>
			{props.children}
		</CoeContext.Provider>
	);
};

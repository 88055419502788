import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CoeApi from "../apis/CoeApi";
import "../css/slides.css";
import "../css/loading.css";
import UpdateSection from "../components/UpdateSection";
const AddUpdateTitleImagesComponent = () => {
	const { labinitial } = useParams();

	const [labtitleandimageslist, setLabtitleandimagesList] = useState();

	const [dropdownmenuSelection, setdropdownmenuSelection] = useState("add");
	const [updateimageTitle, setupdateimageTitle] = useState("");
	const [selectedUpdateimagelink, setselectedUpdateimagelink] = useState("");
	const [updateimageGrouping, setupdateimageGrouping] = useState("");
	const [updateimageWidth, setupdateimageWidth] = useState("");
	const [updateimageImageUrl, setupdateimageImageUrl] = useState("");
	const [updateimagelinkList, setupdateimagelinkList] = useState([]);
	const [
		updatetitleandimagesLinkGrouping,
		setupdatetitleandimagesLinkGrouping,
	] = useState(() => {});

	const [updateimageHeight, setupdateimageHeight] = useState("");

	const saveimageInfo = async () => {
		try {
			const response = await CoeApi.post(`/labs/${labinitial}/titleandimages`, {
				title: updateimageTitle,
				imagegrouping: updateimageGrouping,
				imagelink: updateimageImageUrl,
				maxwidth: updateimageWidth,
				maxheight: updateimageHeight,
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
				clearEverything();
			} else {
				alert("failed");
			}
		} catch (err) {
			console.log(err);
		}
	};

	const updateimageInfo = async () => {
		try {
			const response = await CoeApi.put(`/labs/${labinitial}/titleandimages`, {
				title: updateimageTitle,
				imagegrouping: updateimageGrouping,
				newImagelink: updateimageImageUrl,
				maxwidth: updateimageWidth,
				maxheight: updateimageHeight,
				imagelink: selectedUpdateimagelink,
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
			} else {
				alert("failed");
			}
		} catch (err) {
			console.log(err);
		}
	};

	const clearEverything = () => {
		setupdateimageTitle("");
		setupdateimageGrouping("");
		setupdateimageImageUrl("");
		setupdateimageWidth("");
		setupdateimageHeight("");
	};

	const getSelectedUpdatePart = () => {
		if (dropdownmenuSelection === "add") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Add A image",

							sectionTitle: "image Title",
							sectionTitleValue: updateimageTitle,
							setSectionTitleValue: setupdateimageTitle,

							sectionDescriptionTitle:
								"Does this image belong to any group? If not, leave it blank",
							sectionDescriptionValue: updateimageGrouping,
							setSectionDescriptionValue: setupdateimageGrouping,

							sectionMediaTitle: "Choose/Change image Picture",
							sectionMediaValue: updateimageImageUrl,
							setSectionMediaLink: setupdateimageImageUrl,

							textInputSecondTitle: "Image width (in px)*",
							textInputSecondValue: updateimageWidth,
							settextInputSecondValue: setupdateimageWidth,
							textInputSecondPlaceholder: "400",

							detaillinkTitle: "Image height (in px)",
							detaillinkValue: updateimageHeight,
							setdetaillinkValue: setupdateimageHeight,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={saveimageInfo}
						>
							Save
						</button>
					</div>
				</>
			);
		} else if (dropdownmenuSelection === "update") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Update A image",

							distinguisher: "Title of the image to be updated*",
							distinguisherlist: updateimagelinkList,
							distinguisherValue: selectedUpdateimagelink,
							setdistinguisherValue: setselectedUpdateimagelink,

							sectionTitle: "image Title",
							sectionTitleValue: updateimageTitle,
							setSectionTitleValue: setupdateimageTitle,

							sectionDescriptionTitle:
								"Does this image belong to any group? If not, leave it blank",
							sectionDescriptionValue: updateimageGrouping,
							setSectionDescriptionValue: setupdateimageGrouping,

							sectionMediaTitle: "Choose/Change image Picture",
							sectionMediaValue: updateimageImageUrl,
							setSectionMediaLink: setupdateimageImageUrl,

							textInputSecondTitle: "Image width (in px)*",
							textInputSecondValue: updateimageWidth,
							settextInputSecondValue: setupdateimageWidth,
							textInputSecondPlaceholder: "400",

							detaillinkTitle: "Image height (in px)",
							detaillinkValue: updateimageHeight,
							setdetaillinkValue: setupdateimageHeight,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={updateimageInfo}
						>
							Update
						</button>
					</div>
				</>
			);
		}
	};

	useEffect(() => {
		if (
			updatetitleandimagesLinkGrouping &&
			updatetitleandimagesLinkGrouping[selectedUpdateimagelink]
		) {
			setupdateimageTitle(
				updatetitleandimagesLinkGrouping[selectedUpdateimagelink][0].title
			);
			setupdateimageGrouping(
				updatetitleandimagesLinkGrouping[selectedUpdateimagelink][0]
					.imagegrouping
			);
			setupdateimageImageUrl(
				updatetitleandimagesLinkGrouping[selectedUpdateimagelink][0].imagelink
			);
			setupdateimageWidth(
				updatetitleandimagesLinkGrouping[selectedUpdateimagelink][0].maxwidth
			);
			setupdateimageHeight(
				updatetitleandimagesLinkGrouping[selectedUpdateimagelink][0].maxheight
			);
		}
		return () => {};
	}, [selectedUpdateimagelink]);

	useEffect(() => {
		clearEverything();
		return () => {};
	}, [dropdownmenuSelection]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(`/labs/${labinitial}/titleandimages`);

				setupdatetitleandimagesLinkGrouping(response.data.LabImageLinkGrouping);
				// console.log(response.data.LabImageLinkGrouping)
				setupdateimagelinkList([]);
				for (let id in response.data.LabImageLinkGrouping) {
					setupdateimagelinkList((updateimagelinkList) => [
						...updateimagelinkList,
						id,
					]);
				}
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<div>
			<section className="propub-main-c">
				<div className="propub-landing-c">
					<div className="propub-maintitle-c">
						<h1>titleandimages</h1>
					</div>
				</div>

				<div className="image-example-c">
					<h1>image Example</h1>
					<img
						src="/images/labimageExample.png"
						alt=""
						className="image-example"
					/>
				</div>

				<div className="update-part">
					<label htmlFor="landingMotto">What would you like to do?</label>

					<select
						id="rating"
						className="custom-select"
						value={dropdownmenuSelection}
						onChange={(e) => setdropdownmenuSelection(e.target.value)}
					>
						<option value="add">Add</option>
						<option value="update">Update</option>
					</select>

					{getSelectedUpdatePart()}
				</div>
			</section>
		</div>
	);
};

export default AddUpdateTitleImagesComponent;

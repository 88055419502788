import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { CoeContextProvider } from "./context/CoeContext";

ReactDOM.render(
	<CoeContextProvider>
		<App />
	</CoeContextProvider>,
	document.getElementById("root")
);

import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";
import { useNavigate } from "react-router-dom";

const UpdateLabTitleAndVideos = (props) => {
	let navigate = useNavigate();

	const [LabTitleAndVideos, setLabTitleAndVideos] = useState();

	const myStyle = {
		color: "white",
		backgroundColor: "teal",
		marginLeft: "48px",
		padding: "8px 32px",
	};

	const goToUpdateTitleAndImages = () => {
		navigate(
			`/add/userlabmemberpasswordcoemember234/labs/${props.labinitial}/titleandvideos`
		);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(
					`/labs/${props.labinitial}/titleandvideos`
				);
				setLabTitleAndVideos(response.data.LabTitleAndVideosRows);
				// console.log(response.data.LabTitleAndVideosRows);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<>
			<div className="all-screenshots-c update lab-pictures">
				{LabTitleAndVideos &&
					Object.keys(LabTitleAndVideos).map((key, i) => {
						return (
							<div key={i}>
								<div className="screenshots-title-c">
									{key && <h2 className="">{key}</h2>}
								</div>

								<div className="screenshots-lab-powerpoints-c">
									{LabTitleAndVideos[key].map((eachVideo) => {
										return (
											<div key={eachVideo.id} className="screenshot-c">
												<video
													controls={true}
													src={eachVideo.videolink}
												></video>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
			</div>

			{LabTitleAndVideos && Object.keys(LabTitleAndVideos).length > 0 && (
				<>
					<div>
						<h1>{LabTitleAndVideos.length}</h1>
						<div className="button-c">
							<button style={myStyle} onClick={goToUpdateTitleAndImages}>
								Add/Update Videos
							</button>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default UpdateLabTitleAndVideos;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CoeApi from "../apis/CoeApi";
import "../css/slides.css";
import "../css/loading.css";
import UpdateSection from "../components/UpdateSection";
const AddUpdateTitleVideosComponent = () => {
	const { labinitial } = useParams();

	const [labtitleandvideoslist, setLabtitleandvideosList] = useState();

	const [dropdownmenuSelection, setdropdownmenuSelection] = useState("add");
	const [updatevideoTitle, setupdatevideoTitle] = useState("");
	const [selectedUpdatevideolink, setselectedUpdatevideolink] = useState("");
	const [updatevideoGrouping, setupdatevideoGrouping] = useState("");
	const [updatevideoWidth, setupdatevideoWidth] = useState("");
	const [updatevideoUrl, setupdatevideoUrl] = useState("");
	const [updatevideolinkList, setupdatevideolinkList] = useState([]);
	const [
		updatetitleandvideosLinkGrouping,
		setupdatetitleandvideosLinkGrouping,
	] = useState(() => {});

	const [updatevideoHeight, setupdatevideoHeight] = useState("");

	const savevideoInfo = async () => {
		try {
			const response = await CoeApi.post(`/labs/${labinitial}/titleandvideos`, {
				title: updatevideoTitle,
				videogrouping: updatevideoGrouping,
				videolink: updatevideoUrl,
				maxwidth: updatevideoWidth,
				maxheight: updatevideoHeight,
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
				clearEverything();
			} else {
				alert("failed");
			}
		} catch (err) {
			console.log(err);
		}
	};

	const updatevideoInfo = async () => {
		try {
			const response = await CoeApi.put(`/labs/${labinitial}/titleandvideos`, {
				title: updatevideoTitle,
				videogrouping: updatevideoGrouping,
				newvideolink: updatevideoUrl,
				maxwidth: updatevideoWidth,
				maxheight: updatevideoHeight,
				videolink: selectedUpdatevideolink,
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
			} else {
				alert("failed");
			}
		} catch (err) {
			console.log(err);
		}
	};

	const clearEverything = () => {
		setupdatevideoTitle("");
		setupdatevideoGrouping("");
		setupdatevideoUrl("");
		setupdatevideoWidth("");
		setupdatevideoHeight("");
	};

	const getSelectedUpdatePart = () => {
		if (dropdownmenuSelection === "add") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Add A video",

							sectionTitle: "video Title",
							sectionTitleValue: updatevideoTitle,
							setSectionTitleValue: setupdatevideoTitle,

							sectionDescriptionTitle:
								"Does this video belong to any group? If not, leave it blank",
							sectionDescriptionValue: updatevideoGrouping,
							setSectionDescriptionValue: setupdatevideoGrouping,

							sectionVideoTitle: "Choose/Change video Picture",
							sectionVideoValue: updatevideoUrl,
							setSectionVideoLink: setupdatevideoUrl,

							textInputSecondTitle: "video width (in px)*",
							textInputSecondValue: updatevideoWidth,
							settextInputSecondValue: setupdatevideoWidth,
							textInputSecondPlaceholder: "400",

							detaillinkTitle: "video height (in px)",
							detaillinkValue: updatevideoHeight,
							setdetaillinkValue: setupdatevideoHeight,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={savevideoInfo}
						>
							Save
						</button>
					</div>
				</>
			);
		} else if (dropdownmenuSelection === "update") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Update A video",

							distinguisher: "Title of the video to be updated*",
							distinguisherlist: updatevideolinkList,
							distinguisherValue: selectedUpdatevideolink,
							setdistinguisherValue: setselectedUpdatevideolink,

							sectionTitle: "video Title",
							sectionTitleValue: updatevideoTitle,
							setSectionTitleValue: setupdatevideoTitle,

							sectionDescriptionTitle:
								"Does this video belong to any group? If not, leave it blank",
							sectionDescriptionValue: updatevideoGrouping,
							setSectionDescriptionValue: setupdatevideoGrouping,

							sectionVideoTitle: "Choose/Change video Picture",
							sectionVideoValue: updatevideoUrl,
							setSectionVideoLink: setupdatevideoUrl,

							textInputSecondTitle: "video width (in px)*",
							textInputSecondValue: updatevideoWidth,
							settextInputSecondValue: setupdatevideoWidth,
							textInputSecondPlaceholder: "400",

							detaillinkTitle: "video height (in px)",
							detaillinkValue: updatevideoHeight,
							setdetaillinkValue: setupdatevideoHeight,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={updatevideoInfo}
						>
							Update
						</button>
					</div>
				</>
			);
		}
	};

	useEffect(() => {
		if (
			updatetitleandvideosLinkGrouping &&
			updatetitleandvideosLinkGrouping[selectedUpdatevideolink]
		) {
			setupdatevideoTitle(
				updatetitleandvideosLinkGrouping[selectedUpdatevideolink][0].title
			);
			setupdatevideoGrouping(
				updatetitleandvideosLinkGrouping[selectedUpdatevideolink][0]
					.videogrouping
			);
			setupdatevideoUrl(
				updatetitleandvideosLinkGrouping[selectedUpdatevideolink][0].videolink
			);
			setupdatevideoWidth(
				updatetitleandvideosLinkGrouping[selectedUpdatevideolink][0].maxwidth
			);
			setupdatevideoHeight(
				updatetitleandvideosLinkGrouping[selectedUpdatevideolink][0].maxheight
			);
		}
		return () => {};
	}, [selectedUpdatevideolink]);

	useEffect(() => {
		clearEverything();
		return () => {};
	}, [dropdownmenuSelection]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(`/labs/${labinitial}/titleandvideos`);

				setupdatetitleandvideosLinkGrouping(
					response.data.LabVidelinkGroupingRows
				);
				// console.log(response.data.LabVidelinkGroupingRows)
				setupdatevideolinkList([]);
				for (let id in response.data.LabVidelinkGroupingRows) {
					setupdatevideolinkList((updatevideolinkList) => [
						...updatevideolinkList,
						id,
					]);
				}
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<div>
			<section className="propub-main-c">
				<div className="propub-landing-c">
					<div className="propub-maintitle-c">
						<h1>titleandvideos</h1>
					</div>
				</div>

				<div className="video-example-c">
					<h1>video Example</h1>
					<img
						src="/videos/labvideoExample.png"
						alt=""
						className="video-example"
					/>
				</div>

				<div className="update-part">
					<label htmlFor="landingMotto">What would you like to do?</label>

					<select
						id="rating"
						className="custom-select"
						value={dropdownmenuSelection}
						onChange={(e) => setdropdownmenuSelection(e.target.value)}
					>
						<option value="add">Add</option>
						<option value="update">Update</option>
					</select>

					{getSelectedUpdatePart()}
				</div>
			</section>
		</div>
	);
};

export default AddUpdateTitleVideosComponent;

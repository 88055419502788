import React, { useState } from "react";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebase";
import "../css/styleUpdateSection.css";
import { Link } from "react-router-dom";
const UpdateSection = ({ props }) => {
	const [progress, setProgress] = useState(0);
	const [fileName, setFileName] = useState("");

	const formHandler = (e, type) => {
		e.preventDefault();
		const file = e.target[0].files[0];
		uploadFiles(file, type);
	};

	const uploadFiles = (file, type) => {
		//
		if (!file) return;

		var today = new Date();
		var date =
			today.getFullYear() +
			"-" +
			(today.getMonth() + 1) +
			"-" +
			today.getDate();
		var time =
			today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
		var dateTime = date + " " + time;

		setFileName(file.name);
		const insideFolder = file.name + " " + dateTime;
		const sotrageRef = ref(storage, `coeupdate/${insideFolder}`);
		const uploadTask = uploadBytesResumable(sotrageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const prog = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgress(prog);
			},
			(error) => console.log(error),
			async () => {
				return await getDownloadURL(uploadTask.snapshot.ref).then(
					(downloadURL) => {
						type === "image" && props.setSectionMediaLink(downloadURL);
						type === "video" && props.setSectionVideoLink(downloadURL);
						console.log(downloadURL)
					}
				);
			}
		);
	};

	const saveChanges = async () => {
		try {
			// const response = await ChiaApi.put("/landingtext/1", {
			// 	info: "landingText",
			// 	title: landingMotto,
			// 	description: "",
			// 	imageurl: landingPicUrl,
			// });
			// console.log(response);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div>
			<div className="update-c">
				<h1 className="update-text">{props.method}</h1>

				{/* <div className="form-group col-4">
					<label htmlFor="rating">Rating</label>
					<select
						value={rating}
						onChange={(e) => setRating(e.target.value)}
						id="rating"
						className="custom-select"
					>
						<option disabled>Rating</option>
						<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
						<option value="5">5</option>
					</select>
				</div> */}
				<div className="mb-4">
					<div className="form-row">
						{props.distinguisher && (
							<>
								<label htmlFor="landingMotto">{props.distinguisher}</label>
								<div className="col">
									<select
										value={props.distinguisherValue}
										onChange={(e) =>
											props.setdistinguisherValue(e.target.value)
										}
										id="rating"
										className="custom-select"
									>
										<option>Select</option>
										{props.distinguisherlist &&
											props.distinguisherlist.map((eachoption) => {
												return (
													<option value={eachoption} key={eachoption}>
														{eachoption}
													</option>
												);
											})}
									</select>
								</div>
								<br />
							</>
						)}

						{props.newInfoImmutableTitle && (
							<>
								<label>{props.newInfoImmutableTitle}</label>
								<div>
									<h1 style={{ color: "grey" }}>
										{props.newInfoImmutableValue}
									</h1>
								</div>
								<br />
							</>
						)}

						{props.numberStringTitle && (
							<>
								<label>{props.numberStringTitle}</label>
								<div>
									<textarea
										value={props.numberStringValue}
										onChange={(e) => props.setnumberStringValue(e.target.value)}
										type="text"
										className="form-control"
										placeholder={props.numberStringPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.email && (
							<>
								<label htmlFor="landingMotto">{props.email}</label>
								<div className="col">
									<textarea
										id="landingMotto"
										value={props.emailValue}
										onChange={(e) => props.setemailValue(e.target.value)}
										type="text"
										className="form-control"
										placeholder={props.emailPlaceholder}
										required
									/>
								</div>
								<br />
							</>
						)}

						{props.sectionTitle && (
							<>
								<label htmlFor="landingMotto">{props.sectionTitle}</label>
								<div className="col">
									<textarea
										id="landingMotto"
										value={props.sectionTitleValue}
										onChange={(e) => props.setSectionTitleValue(e.target.value)}
										type="text"
										className="form-control"
										// placeholder={props.sectionTitlePlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.newInfoTitle && (
							<>
								<label>{props.newInfoTitle}</label>
								<div>
									<textarea
										value={props.newInfoValue}
										onChange={(e) => props.setnewInfoValue(e.target.value)}
										type="text"
										className="form-control"
										placeholder={props.newInfoPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.sectionDescriptionTitle && (
							<>
								<label htmlFor="landingMotto">
									{props.sectionDescriptionTitle}
								</label>
								<div className="col">
									<textarea
										id="landingMotto"
										value={props.sectionDescriptionValue}
										onChange={(e) =>
											props.setSectionDescriptionValue(e.target.value)
										}
										type="text"
										className="form-control"
										placeholder={props.sectionDescriptionPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.longinfoTitle && (
							<>
								<label>{props.longinfoTitle}</label>
								<div>
									<textarea
										value={props.longinfoValue}
										onChange={(e) => props.setlonginfoValue(e.target.value)}
										type="text"
										className="form-control"
										placeholder={props.longinfoPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.infosecondTitle && (
							<>
								<label>{props.infosecondTitle}</label>
								<div>
									<textarea
										value={props.infosecondValue}
										onChange={(e) => props.setinfosecondValue(e.target.value)}
										type="text"
										className="form-control"
										placeholder={props.infosecondPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.infothirdTitle && (
							<>
								<label>{props.infothirdTitle}</label>
								<div>
									<textarea
										value={props.infothirdValue}
										onChange={(e) => props.setinfothirdValue(e.target.value)}
										type="text"
										className="form-control"
										placeholder={props.infothirdPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.sectionMediaTitle && (
							<div className="media-part">
								<form onSubmit={(e) => formHandler(e, "image")}>
									<h3 className="image-link-part">
										<span className="image-link-text">
											Image Currently Being Used:{" "}
										</span>{" "}
										<br />
										<span>
											<img
												src={props.sectionMediaValue}
												alt=""
												style={{ width: "200px", height: "200px" }}
											/>
										</span>
									</h3>
									<br />
									<label htmlFor="picture">{props.sectionMediaTitle}</label>
									<br />
									<input
										type="file"
										className="textarea"
										id="picture"
										placeholder="shivam"
									/>
									<div>
										<button type="submit" className="upload-btn">
											Upload Image
										</button>
									</div>
								</form>
								<h2>
									Uploading done {progress}% of file:{" "}
									<span style={{ color: "blue" }}>{fileName}</span>
								</h2>

								<br />
							</div>
						)}

						{props.sectionVideoTitle && (
							<div className="Video-part">
								<form onSubmit={(e) => formHandler(e, "video")}>
									<h3 className="image-link-part">
										<span className="image-link-text">
											Video Currently Being Used:{" "}
										</span>{" "}
										<br />
										<span>
											<video
												loop
												autoPlay={true}
												muted
												src={props.sectionVideoValue}
												alt=""
												style={{ width: "200px", height: "200px" }}
											/>
										</span>
									</h3>
									<br />
									<label htmlFor="picture">{props.sectionVideoTitle}</label>
									<br />
									<input
										type="file"
										className="textarea"
										id="picture"
										placeholder="shivam"
									/>
									<div className="button-c">
										<button type="submit" className="upload-btn">
											Upload Image
										</button>
									</div>
								</form>
								<h2>
									Uploading done {progress}% of file:{" "}
									<span style={{ color: "blue" }}>{fileName}</span>
								</h2>

								<br />
							</div>
						)}

						{props.labnameTitle && (
							<>
								<label>{props.labnameTitle}</label>
								<div>
									<textarea
										value={props.labnameValue}
										onChange={(e) => props.setlabnameValue(e.target.value)}
										type="text"
										className="form-control"
										placeholder={props.labnamePlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.labinitialTitle && (
							<>
								<label>{props.labinitialTitle}</label>
								<div>
									<textarea
										value={props.labinitialValue}
										onChange={(e) => props.setlabinitialValue(e.target.value)}
										type="text"
										className="form-control"
										placeholder={props.labinitialPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.postTitle && (
							<>
								<label htmlFor="landingMotto">{props.postTitle}</label>
								<div className="col">
									<textarea
										id="landingMotto"
										value={props.postValue}
										onChange={(e) => props.setPostValue(e.target.value)}
										type="text"
										className="form-control"
										placeholder={props.postPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.lablinkTitle && (
							<>
								<label>{props.lablinkTitle}</label>
								<div>
									<textarea
										value={props.lablinkValue}
										onChange={(e) => props.setlablinkValue(e.target.value)}
										type="text"
										className="form-control"
										placeholder={props.lablinkPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.detaillinkTitle && (
							<>
								<label>{props.detaillinkTitle}</label>
								<div>
									<textarea
										value={props.detaillinkValue}
										onChange={(e) => props.setdetaillinkValue(e.target.value)}
										type="text"
										className="form-control"
										placeholder={props.detaillinkPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.textInputSecondTitle && (
							<>
								<label>{props.textInputSecondTitle}</label>
								<div>
									<textarea
										value={props.textInputSecondValue}
										onChange={(e) =>
											props.settextInputSecondValue(e.target.value)
										}
										type="text"
										className="form-control"
										placeholder={props.textInputSecondPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.textInputThirdTitle && (
							<>
								<label>{props.textInputThirdTitle}</label>
								<div>
									<textarea
										value={props.textInputThirdValue}
										onChange={(e) =>
											props.settextInputThirdValue(e.target.value)
										}
										type="text"
										className="form-control"
										placeholder={props.textInputThirdPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.textInputFourthTitle && (
							<>
								<label>{props.textInputFourthTitle}</label>
								<div>
									<textarea
										value={props.textInputFourthValue}
										onChange={(e) =>
											props.settextInputFourthValue(e.target.value)
										}
										type="text"
										className="form-control"
										placeholder={props.textInputFourthPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.textInputFifthTitle && (
							<>
								<label>{props.textInputFifthTitle}</label>
								<div>
									<textarea
										value={props.textInputFifthValue}
										onChange={(e) =>
											props.settextInputFifthValue(e.target.value)
										}
										type="text"
										className="form-control"
										placeholder={props.textInputFifthPlaceholder}
									/>
								</div>
								<br />
							</>
						)}

						{props.distinguisherSecond && (
							<>
								<label htmlFor="landingMotto">
									{props.distinguisherSecond}
								</label>
								<div className="col">
									<select
										value={props.distinguisherSecondValue}
										onChange={(e) =>
											props.setdistinguisherSecondValue(e.target.value)
										}
										id="rating"
										className="custom-select"
									>
										<option>Select</option>
										{props.distinguisherSecondlist &&
											props.distinguisherSecondlist.map((eachoption) => {
												return (
													<option value={eachoption} key={eachoption}>
														{eachoption}
													</option>
												);
											})}
									</select>
								</div>
								<br />
							</>
						)}

						{props.distinguisherThird && (
							<>
								<label htmlFor="landingMotto">{props.distinguisherThird}</label>
								<div className="col">
									<select
										value={props.distinguisherThirdValue}
										onChange={(e) =>
											props.setdistinguisherThirdValue(e.target.value)
										}
										id="rating"
										className="custom-select"
									>
										<option>Select</option>
										{props.distinguisherThirdlist &&
											props.distinguisherThirdlist.map((eachoption) => {
												return (
													<option value={eachoption} key={eachoption}>
														{eachoption}
													</option>
												);
											})}
									</select>
								</div>
								<br />
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpdateSection;

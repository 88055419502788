import React, { useContext, useState } from "react";
import SetfacultyContactLinkList from "../UpdateComponents/SetFacultyContactList";
import "../css/facultyContactDesign.css";
import { CoeContext } from "../context/CoeContext";
const FacultyContact = () => {
	const { facultyContactListGrouping } = useContext(CoeContext);

	return (
		<>
			<SetfacultyContactLinkList />
			<div className="facultycontact-c">
				<div>
					{facultyContactListGrouping &&
						Object.keys(facultyContactListGrouping).map((eachGrouping) => {
							return (
								<div key={eachGrouping}>
									<h1 className="facultycontact-grouping">{eachGrouping}</h1>
									{facultyContactListGrouping[eachGrouping].map(
										(eachFacultyContact) => {
											return (
												<div
													key={eachFacultyContact.id}
													className="each-facultycontact-c"
												>
													{eachFacultyContact.imagelink !== "" && (
														<div className="facultycontact-img-c">
															<img
																src={eachFacultyContact.imagelink}
																alt=""
																className="facultycontact-img"
															/>
														</div>
													)}

													<div className="facultycontact-text-c">
														<h2 className="facultycontact-name ">
															{eachFacultyContact.name &&
																eachFacultyContact.name}
														</h2>
														<p className="facultycontact-email facultycontact-textpart">
															Email:{" "}
															{eachFacultyContact.email &&
																eachFacultyContact.email}
														</p>
														<a
															href={
																eachFacultyContact.detaillink &&
																eachFacultyContact.detaillink
															}
															className="facultycontact-detaillink facultycontact-textpart"
															target="_blank"
															rel="noopener noreferrer"
														>
															Details
														</a>
													</div>
												</div>
											);
										}
									)}
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};

export default FacultyContact;

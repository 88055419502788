import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";

const LabHeader = (props) => {
	const [labHeader, setLabHeader] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(`/labs/${props.labinitial}/header`);
				setLabHeader(response.data.LabHeaderRow);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<div className="lab-intro">
			<div className="lab-intro-image-c">
				<img src="/images/imagePlaceholder.jpg" alt="" />
			</div>

			<div className="lab-intro-text-wrapper">
				<div className="lab-intro-texts-c">
					<div className="lab-name-c">
						<h1>{labHeader ? labHeader.labname : "Loading ..."}</h1>
					</div>

					<div className="lab-aim-c ">
						<p className="lab-aim">{labHeader && labHeader.lablongintro}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LabHeader;

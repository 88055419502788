import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";

const LabMembers = (props) => {
	const [members, setMembers] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(`/labs/${props.labinitial}/members`);
				setMembers(response.data.LabMembersRows);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<div className="lab-student-workers-c">
			<div className="student-worker-type-name-c">
				<h2 className="student-worker-type-name">Lab Members</h2>
			</div>

			<div className="all-custom-cards-wrapper">
				<div className="custom-card-c jasperLabImage">
					{members &&
						members.map((member) => {
							return (
								<div key={member.name} className="custom-card">
									<div className="custom-card-img-c ">
										<img src={member.imagelink} alt="" />
									</div>
									<div className="custom-card-info-c">
										<div className="custom-card-name-c custom-card-info-elements">
											<h2 className="custom-card-name">{member.name}</h2>
											<h2>{member.post}</h2>
											<h2>{member.post1}</h2>
											{member.workingfor && <h2>({member.workingfor})</h2>}
										</div>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default LabMembers;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CoeApi from "../apis/CoeApi";
import ImageHandler from "../components/ImageHandler";
import { CoeContext } from "../context/CoeContext";

import "../css/genericUpdateStyle.css";
import SetfacultyContactLinkList from "./SetFacultyContactList";

const AddFacultyContact = () => {
	let navigate = useNavigate();


	const [facultyContactObj, setFacultyContactObj] = useState({
		name: "",
		email: "",
		detaillink: "",
		imagelink: "",
		grouping: "",
	});
	const [tempGrouping, setTempGrouping] = useState("");
	const [groupingList, setGroupingList] = useState(new Set());
	groupingList.add("Other");
	const { name, email, detaillink, grouping } = facultyContactObj;
	const { facultyContactListGrouping } = useContext(CoeContext);

	useEffect(() => {
		const fetchResult = async () => {
			try {
				if (facultyContactListGrouping) {
					Object.keys(facultyContactListGrouping).forEach((element) =>
						setGroupingList((prev) => new Set(prev.add(element)))
					);
				}
			} catch (err) {}
		};
		fetchResult();
	}, [facultyContactListGrouping]);

	useEffect(() => {
		if (tempGrouping !== "Other") {
			setFacultyContactObj({ ...facultyContactObj, grouping: tempGrouping });
		}
	}, [tempGrouping]);

	const onHandleChange = (e) =>
		setFacultyContactObj({
			...facultyContactObj,
			[e.target.name]: e.target.value,
		});

	const getGroupingTextBox = () => {
		if (tempGrouping === "Other") {
			
			return (
				<>
					<h4 >What's the grouping faculty belongs to?</h4>

					<textarea
						type="text"
						name="grouping"						
						value={grouping}
						onChange={(e) => onHandleChange(e)}
					/>
				</>
			);
		}
	};
	const handleSubmitForm = async (e) => {
		e.preventDefault();

		const facultyContactObject = JSON.stringify(facultyContactObj);
		try {
			const response = await CoeApi.post(`/facultycontact`, {
				facultyContactObject,
			});
			if (response.status === 200 || response.status === 201) {
				alert("success");
				navigate(`/test`);
				navigate(`/update/usercoeresearchpasswordeureka99/facultycontact`);
			} else if (response.status === 204) {
				alert(
					"Failed! Values not entered like they are supposed to. Please try again or contact your web developer for assistance."
				);
			}
		} catch (err) {
			console.error(err.message);
		}
	};

	return (
		<>
			<SetfacultyContactLinkList />
			<div className="generic-update-c">
				<h1 className="">Add A Faculty Contact Info</h1>
				<div>
					<h4 htmlFor="name">Faculty Contact name</h4>

					<div>
						<textarea
							type="text"
							name="name"
							value={name}
							onChange={(e) => onHandleChange(e)}
						/>
					</div>

					<h4 htmlFor="email">Faculty email</h4>
					<div>
						<textarea
							type="text"
							name="email"
							value={email}
							onChange={(e) => onHandleChange(e)}
						/>
					</div>

					<ImageHandler
						props={{
							sectionMediaTitle: "faculty image",
							sectionMediaValue: facultyContactObj,
							setSectionMediaLink: setFacultyContactObj,
						}}
					/>

					<h4 htmlFor="detaillink">Enter a link for faculty's detail</h4>

					<div>
						<textarea
							type="text"
							name="detaillink"
							value={detaillink}
							onChange={(e) => onHandleChange(e)}
						/>
					</div>

					<h4 htmlFor="grouping">What's the grouping faculty belongs to?</h4>
					<div>
						<select
							value={tempGrouping}
							onChange={(e) => setTempGrouping(e.target.value)}
						>
							<option>Select</option>
							{groupingList &&
								Array.from(groupingList).map((eachoption) => {
									return (
										<option value={eachoption} key={eachoption}>
											{eachoption}
										</option>
									);
								})}
						</select>
					</div>

					{getGroupingTextBox()}

					<button className="" onClick={handleSubmitForm}>
						Add
					</button>
				</div>
			</div>
		</>
	);
};

export default AddFacultyContact;

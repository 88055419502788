import {
	BrowserRouter as Router,
	Routes, // instead of "Switch"
	Route,
} from "react-router-dom";
// import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import FacultyContact from "./routes/FacultyContact";

import Home from "./routes/Home";

import Labpage from "./routes/Labpage";

import Labs from "./routes/Labs";
import AddUpdateEquipmentsComponent from "./UpdateComponents/AddUpdateEquipmentsComponent";
import AddUpdateTitleImagesComponent from "./UpdateComponents/AddUpdateTitleImagesComponent";
import AddUpdateTitleVideosComponent from "./UpdateComponents/AddUpdateTitleVideosComponent";
import ChangeFacultyContact from "./UpdateComponents/ChangeFacultyContact";
import SpecificFacultyContactUpdate from "./UpdateComponents/SpecificFacultyContactUpdate";
import UpdateHome from "./UpdateComponents/UpdateHome";
import UpdateLabEquipments from "./UpdateComponents/UpdateLabEquipments";
import UpdateLabFaculty from "./UpdateComponents/UpdateLabFaculty";
import UpdateLabpage from "./UpdateComponents/UpdateLabpage";
import UpdateLabProjects from "./UpdateComponents/UpdateLabProjects";
import UpdateLabPublications from "./UpdateComponents/UpdateLabPublications";
import UpdateLabs from "./UpdateComponents/UpdateLabs";
import UpdateLabStudents from "./UpdateComponents/UpdateLabStudents";
import UpdateLabTitleAndImages from "./UpdateComponents/UpdateLabTitleAndImages";

const App = () => {
	return (
		<div>
			<Router>
				<Navigation />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route exact path="/labs" element={<Labs />} />
					<Route exact path="/labs/:labinitial" element={<Labpage />} />

					<Route
						exact
						path="/update/usercoeresearchpasswordeureka99/"
						element={<UpdateHome />}
					/>
					<Route
						exact
						path="/update/usercoeresearchpasswordeureka99/labs/"
						element={<UpdateLabs />}
					/>
					<Route
						exact
						path="/update/usercoeresearchpasswordeureka99/labs/:labinitial"
						element={<UpdateLabpage />}
					/>		

					<Route
						exact
						path="/add/userlabmemberpasswordcoemember234/labs/:labinitial/student"
						element={<UpdateLabStudents />}
					/>					
					<Route
						exact
						path="/add/userlabmemberpasswordcoemember234/labs/:labinitial/projects"
						element={<UpdateLabProjects />}
					/>					
					<Route
						exact
						path="/add/userlabmemberpasswordcoemember234/labs/:labinitial/titleandimages"
						element={<AddUpdateTitleImagesComponent />}
					/>					
					<Route
						exact
						path="/add/userlabmemberpasswordcoemember234/labs/:labinitial/labequipments"
						element={<AddUpdateEquipmentsComponent />}
					/>					
					<Route
						exact
						path="/add/userlabmemberpasswordcoemember234/labs/:labinitial/titleandvideos"
						element={<AddUpdateTitleVideosComponent />}
					/>					
					<Route
						exact
						path="/update/usercoeresearchpasswordeureka99/facultycontact"
						element={<ChangeFacultyContact />}
					/>

					<Route
						exact
						path="/update/usercoeresearchpasswordeureka99/facultycontact/:id"
						element={<SpecificFacultyContactUpdate />}
					/>					
					<Route
						exact
						path="/connectwithfaculty"
						element={<FacultyContact />}
					/>					
				</Routes>
				{/* <Footer /> */}
			</Router>
		</div>
	);
};

export default App;

import React, { useContext, useEffect } from "react";
import { CoeContext } from "../context/CoeContext";

const LandingMission = () => {
	const { landingVariables } = useContext(CoeContext);

	return (
		<>
			{landingVariables["missionStatement"] && (
				<div className="landing-mission-c">
					<div className="mission-picture-c">
						<img
							src={landingVariables["missionStatement"][0].imagelink}
							alt=""
							className="mission-picture"
						/>
					</div>

					<div className="mission-statement-c">
						<h3 className="mission-statement-title">
							{landingVariables["missionStatement"][0].title}
						</h3>
						<div className="mission-statement-para-c">
							<p className="mission-statement-para">
								{landingVariables["missionStatement"][0].description}
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LandingMission;

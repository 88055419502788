import React, { useContext, useEffect } from "react";
import CoeApi from "../apis/CoeApi";
import { CoeContext } from "../context/CoeContext";

const SetfacultyContactLinkList = ({ props }) => {

	const { setFacultyContactList, setFacultyContactListGrouping } = useContext(CoeContext);

	useEffect(() => {
		const fetchResult = async () => {
			try {
				const facultyContactData = await CoeApi.get("/facultycontact");
				setFacultyContactList(facultyContactData.data.FacultyContactRows);
				setFacultyContactListGrouping(facultyContactData.data.FacultyContactGrouping);
			} catch (err) {}
		};
		fetchResult();
	}, []);
	return <div></div>;
};

export default SetfacultyContactLinkList;

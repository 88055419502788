import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";

const LabProjects = (props) => {
	const [labProjects, setLabProjects] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(`/labs/${props.labinitial}/projects`);
				setLabProjects(response.data.LabProjectsRows);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<div className="lab-research-projects-c jasperLabCurrentProjects">
			{labProjects &&
				Object.keys(labProjects).map((key, i) => {
					return (
						<div key={i}>
							<div className="lab-research-projects-title-c">
								<h2 className="lab-research-projects-title">
									{" "}
									{key === "" ? "Projects" : key}
								</h2>
							</div>
							<div className="all-research-projects-c">
								{labProjects[key].map((eachProject) => {
									return (
										<div
											key={eachProject.id}
											className="each-research-project-c"
										>
											<div className="research-project-name-c">
												<h3 className="research-project-name">
													{eachProject.title}
												</h3>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default LabProjects;

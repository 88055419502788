import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";

const LabContactPerson = (props) => {
	const [labContactPerson, setLabContactPerson] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(
					`/labs/${props.labinitial}/contactperson`
				);
				setLabContactPerson(response.data.LabContactPersonRow);
				// console.log(response.data.LabContactPersonRow)
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<div className="director-info-c">
			<div className="director-info-box-1">
				<div className="director-img-c">
					<img
						src={labContactPerson && labContactPerson.imagelink}
						alt=""
						className="director-img"
					/>
				</div>

				<div className="director-info-section-title-c">
					<h1 className="director-info-section-title">
						{labContactPerson && labContactPerson.labpost}
					</h1>
				</div>
				<div className="lab-director-c">
					<h2 className="lab-director">
						{labContactPerson ? labContactPerson.name : "Loading ..."}
					</h2>
				</div>

				<div className="lab-director-email-c">
					<h2 className="lab-director-email">
						<span id="font-logo" className="fas fa-envelope"></span>
						{labContactPerson && labContactPerson.email}
					</h2>
				</div>

				<div className="lab-director-cv-link-c">
					<p className="lab-director-cv-link-wrapper">
						<a
							href={labContactPerson && labContactPerson.detaillink}
							target="_blank"
							rel="noopener noreferrer"
							className="lab-director-cv-link"
						>
							{labContactPerson && labContactPerson.detailbuttontitle}
						</a>
					</p>
				</div>
			</div>

			<div className="director-info-box-2">
				<div className="lab-director-desc-c">
					<p className="lab-director-desc">
						{labContactPerson ? labContactPerson.detail : "Please Wait ..."}
					</p>
				</div>
			</div>
		</div>
	);
};

export default LabContactPerson;

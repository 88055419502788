import axios from "axios";

const baseURL =
	process.env.NODE_ENV === "production"
		? "https://uttylerchiaserver.com/coe/api/v1"
		: "http://localhost:4000/coe/api/v1";

export default axios.create({
	baseURL,
});

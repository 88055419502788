import React, { useEffect, useState } from "react";
import LabsApi from "../apis/CoeApi";
import { useNavigate } from "react-router-dom";

import "../css/labsNavigation.css";

const Labs = () => {
	const [labList, setLabList] = useState();
	let navigate = useNavigate();

	useEffect(() => {
		const fetchLabListing = async () => {
			try {
				const result = await LabsApi.get("/labs");
				setLabList(result.data.LabListingRows);
				console.log(result.data.LabListingRows);
			} catch (err) {}
		};
		fetchLabListing();
	}, []);

	const handleLabSelect = (labinitial) => {
		navigate(`/labs/${labinitial}`);
		// navigate({ pathname: `/labs/${labinitial}` })
	};
	return (
		<div className="labs-page-main-c">
			{labList ? (
				<div className="labs-containers-c">
					{labList &&
						labList.map((lab) => {
							return (
								<div key={lab.labinitial}>
									<div className="labs-c">
										<div className="each-labs-c">
											<div
												className="lab-details-and-image-c"
												onClick={() => handleLabSelect(lab.labinitial)}
											>
												<img src={lab.labcoverimagelink} alt="" />
												<div className="lab-details-c">
													<h2>
														{lab.labname} ({lab.labinitial})
													</h2>
													<p>{lab.labshortintro}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
				</div>
			) : (
				<div className="labs-containers-c">
					<h1 className="loading-style">Loading Labs List ...</h1>
				</div>
			)}
		</div>
	);
};

export default Labs;

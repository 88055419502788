import React from "react";
import AddFacultyContact from "./AddFacultyContact";
import FacultyContactListUpdate from "./FacultyContactListUpdate";

const ChangeFacultyContact = () => {
	return (
		<>
			<AddFacultyContact />
			<FacultyContactListUpdate />
		</>
	);
};

export default ChangeFacultyContact;

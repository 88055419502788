import React from "react";
import { Link } from "react-router-dom";
import "../css/common.css";

const Navigation = () => {
	const url = window.location.href;

	return (
		<div>
			<nav className="navigation">
				<input id="toggle-checkbox" type="checkbox" />
				<ul>
					<li>
						<Link to={"/"} className="v">
							Home
						</Link>
					</li>

					<li>
						<Link
							to={
								url.includes("update")
									? "/update/usercoeresearchpasswordeureka99/labs/"
									: "./labs"
							}
						>
							Labs & Centers
						</Link>
						<ul>
							{/* <li>
								<Link to={"/labs/BRL"} >Biomaterials Lab</Link>
							</li>
							<li>
								<Link to={"/labs/BAQE"}>
									Building Air Quality and Energy (BAQE) Research Center
								</Link>
							</li>
							<li>
								<Link to={"/labs/ESL"}>
									Energy System Laboratory (ESL)
								</Link>
							</li>
							<li>
								<Link to={"/labs/JCEL"}>
									Jasper Chemical Engineering Laboratory
								</Link>
							</li>
							<li>
								<Link to={"/labs/MRL"}>
									Mechatronics and Robotics Lab
								</Link>
							</li>
							<li>
								<Link to={"/labs/RRRL"}>
									Rehabilitation Robotics and Research Laboratory
								</Link>
							</li> */}
						</ul>
					</li>

					<li>
						<a href="#">Resources</a>
						<ul>
							<li>
								<a href="#">Resources for Faculty</a>
							</li>
							<li>
								<a href="#">Resources for Students</a>
							</li>
						</ul>
					</li>

					<li>
						<a href="#">Events</a>
						<ul>
							<li>
								<a href="#">Event x</a>
							</li>
							<li>
								<a href="#">Event y</a>
							</li>
							<li>
								<a href="#">Event z</a>
							</li>
							<li>
								<a href="#">Sponsor an event</a>
							</li>
						</ul>
					</li>

					<li>
						<a href="#">News & Highlights</a>
						<ul>
							<li>
								<a href="#">Recent Funding</a>
							</li>
							<li>
								<a href="#">Faculty Awards</a>
							</li>
							<li>
								<a href="#">Conference Publications</a>
							</li>
							<li>
								<a href="#">Best Thesis</a>
							</li>
						</ul>
					</li>

					<li>
						<Link to={"/"} className="v">
							Connect
						</Link>

						<ul>
							<li>
								<Link to={"/connectwithfaculty"}>Faculty & Research Staff</Link>
							</li>
							<li>
								<Link to={"#"}>Collaborate With Us</Link>
							</li>
						</ul>
					</li>
					<li>
						<a href="#">Give</a>
						<ul>
							<li>
								<a href="#">Gifts</a>
							</li>
							<li>
								<a href="#">Get Involved</a>
							</li>
						</ul>
					</li>
				</ul>

				<div>
					<img src="/images/logo.png" alt="" className="coe-logo" />
				</div>

				<label htmlFor="toggle-checkbox" className="hamburger-icon">
					<div className="line"></div>
					<div className="line"></div>
					<div className="line"></div>
				</label>
			</nav>
		</div>
	);
};

export default Navigation;

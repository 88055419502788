import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";
import { useNavigate } from "react-router-dom";

const UpdateLabTitleAndImages = (props) => {
	let navigate = useNavigate();

	const [LabTitleAndImages, setLabTitleAndImages] = useState();

	const myStyle = {
		color: "white",
		backgroundColor: "teal",
		marginLeft: "48px",
		padding: "8px 32px",
	};

	const goToUpdateTitleAndImages = () => {
		navigate(
			`/add/userlabmemberpasswordcoemember234/labs/${props.labinitial}/titleandimages`
		);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(
					`/labs/${props.labinitial}/titleandimages`
				);
				setLabTitleAndImages(response.data.LabTitleAndImagesRows);

				// console.log(response.data.LabTitleAndImagesRows);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<>
			<div
				className="all-screenshots-c lab-pictures update"				
			>
				{LabTitleAndImages &&
					Object.keys(LabTitleAndImages).map((key, i) => {
						return (
							<div key={key}>
								<div className="screenshots-title-c">
									<h2 className="">{key}</h2>
								</div>

								<div className="screenshots-lab-powerpoints-c">
									{LabTitleAndImages[key].map((eachImage) => {
										return (
											<div key={eachImage.id} className="screenshot-c">
												<img
													src={eachImage.imagelink}
													alt=""
													style={{
														maxWidth: eachImage.maxwidth
															? eachImage.maxwidth
															: "640px",
														maxHeight: eachImage.maxHeight
															? eachImage.maxHeight
															: "417px",
													}}
												/>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
			</div>

			{LabTitleAndImages && Object.keys(LabTitleAndImages).length > 0 && (
				<>
					<div>
						<h1>{LabTitleAndImages.length}</h1>
						<div className="button-c">
							<button style={myStyle} onClick={goToUpdateTitleAndImages}>
								Add/Update Images
							</button>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default UpdateLabTitleAndImages;

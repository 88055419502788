import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";
import UpdateSection from "../components/UpdateSection";
import "../css/loading.css";

import { useNavigate } from "react-router-dom";

const UpdateLabContactPerson = (props) => {
	let navigate = useNavigate();

	const [contactPersonInfo, setcontactPersonInfo] = useState("");
	const [facultyEmailGrouping, setfacultyEmailGrouping] = useState(() => {});
	const [updatefacultyEmailList, setupdatefacultyEmailList] = useState([]);

	const [updateEmail, setupdateEmail] = useState("");
	const [updateNewEmail, setupdateNewEmail] = useState("");
	const [updateName, setupdateName] = useState(() => {
		return "";
	});
	const [updateinfo, setupdateinfo] = useState("");
	const [updatemedialink, setupdatemedialink] = useState("");
	const [updatepost, setupdatepost] = useState("");
	const [updateLonginfo, setupdateLonginfo] = useState("");
	const [updateLabname, setupdateLabname] = useState("");
	const [updateLabinitial, setupdateLabinitial] = useState("");
	const [labInitialsList, setlabInitialsList] = useState([]);
	const [detailButtonTitle, setdetailButtonTitle] = useState("");
	const [updateDetaillink, setupdateDetaillink] = useState("");
	const [selectedFacultyForUpdate, setSelectedFacultyForUpdate] = useState(
		() => {}
	);
	const [dropdownmenuSelection, setdropdownmenuSelection] = useState("add");
	const saveInfo = async () => {
		try {
			const response = await CoeApi.post(
				`/labs/${props.labinitial}/labcontactperson`,
				{
					email: updateEmail,
					name: updateName,
					labPost: updatepost,
					detail: updateinfo,
					detailLink: updateDetaillink,
					detailButtonTitle: detailButtonTitle,
					imagelink: updatemedialink,
				}
			);
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
				// clearEverything();
				navigate("/");
				navigate(
					`/update/usercoeresearchpasswordeureka99/labs/${props.labinitial}`
				);
			} else if (response.status === 204) {
				alert(
					"Failed! Values not entered like they are supposed to. Please try again or contact your web developer for assistance."
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const updateInfo = async () => {
		try {
			const response = await CoeApi.put(
				`/labs/${props.labinitial}/labcontactperson/${updateEmail}`,
				{
					email: updateNewEmail,
					name: updateName,
					labPost: updatepost,
					detail: updateinfo,
					detailLink: updateDetaillink,
					detailButtonTitle: detailButtonTitle,
					imagelink: updatemedialink,
				}
			);
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
				navigate("/");
				navigate(
					`/update/usercoeresearchpasswordeureka99/labs/${props.labinitial}`
				);
			} else if (response.status === 204) {
				alert(
					"Failed! Values not entered like they are supposed to. Please try again or contact your web developer for assistance."
				);
			}
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		// const fetchFaculty = async () => {
		// 	try {
		// 		const result = await CoeApi.get(
		// 			`/labs/${props.labinitial}/contactperson`
		// 		);
		// 		// console.log(result.data.LabContactPersonRow);
		// 		setupdateEmail(result.data.LabContactPersonRow.email);
		// 		setupdatemedialink(result.data.LabContactPersonRow.imagelink);
		// 		setupdateName(result.data.LabContactPersonRow.name);
		// 		setupdatepost(result.data.LabContactPersonRow.post);
		// 		setdetailButtonTitle(result.data.LabContactPersonRow.detailbuttontitle);
		// 		setupdateinfo(result.data.LabContactPersonRow.detail);
		// 		setupdateDetaillink(result.data.LabContactPersonRow.detaillink);
		// 	} catch (err) {}
		// };
		// fetchFaculty();
	}, []);

	const fillValuesByEmail = async () => {
		// facultyEmailGrouping.length() && console.log(facultyEmailGrouping[updateEmail][0])
		// facultyEmailGrouping && facultyEmailGrouping[updateEmail]
		// && console.log("okay")
		// && setselectedFacultyForUpdate(facultyEmailGrouping[updateEmail][0])
		// setupdateName(facultyEmailGrouping && facultyEmailGrouping[updateEmail] && facultyEmailGrouping[updateEmail][0].name)
	};

	// useEffect(() => {
	// 	if (facultyEmailGrouping && facultyEmailGrouping[updateEmail]) {
	// 		setupdateEmail(facultyEmailGrouping[updateEmail][0].email);
	// 		setupdateName(facultyEmailGrouping[updateEmail][0].name);
	// 		setupdateinfo(facultyEmailGrouping[updateEmail][0].info);
	// 		setupdatemedialink(facultyEmailGrouping[updateEmail][0].imagelink);
	// 		setupdatepost(facultyEmailGrouping[updateEmail][0].post);
	// 		setupdateLonginfo(facultyEmailGrouping[updateEmail][0].longinfo);
	// 		setupdateLabname(facultyEmailGrouping[updateEmail][0].labname);
	// 		setupdateLabinitial(facultyEmailGrouping[updateEmail][0].labinitial);
	// 		setdetailButtonTitle(facultyEmailGrouping[updateEmail][0].lablink);
	// 		setupdateDetaillink(facultyEmailGrouping[updateEmail][0].detaillink);
	// 	}
	// 	return () => {};
	// }, [contactPersonInfo]);

	useEffect(() => {
		if (dropdownmenuSelection === "add") {
			clearEverything();
		}

		if (dropdownmenuSelection === "update") {
			console.log("update selected");
			const fetchFaculty = async () => {
				try {
					const result = await CoeApi.get(
						`/labs/${props.labinitial}/contactperson`
					);
					console.log(result.data.LabContactPersonRow);
					setupdateEmail(result.data.LabContactPersonRow.email);
					setupdateNewEmail(result.data.LabContactPersonRow.email);
					result.data.LabContactPersonRow.imagelink &&
						setupdatemedialink(result.data.LabContactPersonRow.imagelink);
					result.data.LabContactPersonRow.name &&
						setupdateName(result.data.LabContactPersonRow.name);
					result.data.LabContactPersonRow.labpost &&
						setupdatepost(result.data.LabContactPersonRow.labpost);
					result.data.LabContactPersonRow.detailbuttontitle &&
						setdetailButtonTitle(
							result.data.LabContactPersonRow.detailbuttontitle
						);
					setupdateinfo(result.data.LabContactPersonRow.detail);
					result.data.LabContactPersonRow.detaillink &&
						setupdateDetaillink(result.data.LabContactPersonRow.detaillink);
				} catch (err) {}
			};
			fetchFaculty();
		}
		return () => {};
	}, [dropdownmenuSelection]);

	const clearEverything = () => {
		setupdateEmail("");
		setupdateName("");
		setupdateinfo("");
		setupdatemedialink("");
		setupdatepost("");
		setupdateLonginfo("");
		setupdateLabname("");
		setupdateLabinitial("");
		setdetailButtonTitle("");
		setupdateDetaillink("");
	};

	const setInputValues = () => {};

	const getSelectedUpdatePart = () => {
		if (dropdownmenuSelection === "add") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Add Contact Person Info",

							email: "Contactperson Email",
							emailValue: updateEmail,
							setemailValue: setupdateEmail,

							sectionTitle: "Name",
							sectionTitleValue: updateName,
							setSectionTitleValue: setupdateName,

							sectionDescriptionTitle: "Short Info (2 Statements)",
							sectionDescriptionValue: updateinfo,
							setSectionDescriptionValue: setupdateinfo,

							sectionMediaTitle: "Choose Picture",
							sectionMediaValue: updatemedialink,
							setSectionMediaLink: setupdatemedialink,

							postTitle: "Contactperson's Post in the lab",
							postValue: updatepost,
							setPostValue: setupdatepost,
							postPlaceholder: "e.g. Director",

							lablinkTitle: "What should we call your detail button?",
							lablinkValue: detailButtonTitle,
							setlablinkValue: setdetailButtonTitle,
							lablinkPlaceholder: "e.g. More Contacts",

							detaillinkTitle: "Contact Person's Detail Link",
							detaillinkValue: updateDetaillink,
							setdetaillinkValue: setupdateDetaillink,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={saveInfo}
						>
							Save
						</button>
					</div>
				</>
			);
		} else if (dropdownmenuSelection === "update") {
			setInputValues();
			return (
				<>
					<UpdateSection
						props={{
							method: "Update Contact Person Info",

							newInfoImmutableTitle: "Contactperson Email",
							newInfoImmutableValue: updateEmail,

							email:
								"New Contactperson Email (if you want to update, otherwise please leave as it is)",
							emailValue: updateNewEmail,
							setemailValue: setupdateNewEmail,

							sectionTitle: "Name",
							sectionTitleValue: updateName,
							setSectionTitleValue: setupdateName,

							sectionDescriptionTitle: "Short Info (2 Statements)",
							sectionDescriptionValue: updateinfo,
							setSectionDescriptionValue: setupdateinfo,

							sectionMediaTitle: "Choose Picture",
							sectionMediaValue: updatemedialink,
							setSectionMediaLink: setupdatemedialink,

							postTitle: "Contactperson's Post in the lab",
							postValue: updatepost,
							setPostValue: setupdatepost,

							lablinkTitle: "What should we call your detail button?",
							lablinkValue: detailButtonTitle,
							setlablinkValue: setdetailButtonTitle,

							detaillinkTitle: "Contact Person's Detail Link",
							detaillinkValue: updateDetaillink,
							setdetaillinkValue: setupdateDetaillink,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={updateInfo}
						>
							Save
						</button>
					</div>
				</>
			);
		}
	};

	return (
		<div>
			<div className="update-part">
				<h1 style={{ marginBottom: "24px" }}> </h1>

				{/* <h1>{updateinfo}</h1> */}

				<div className="update-part">
					<h1 style={{ marginBottom: "24px" }}> </h1>

					{/* <h1>{updateinfo}</h1> */}
					<label htmlFor="landingMotto">What would you like to do?</label>

					<select
						id="rating"
						className="custom-select"
						value={dropdownmenuSelection}
						onChange={(e) => setdropdownmenuSelection(e.target.value)}
					>
						<option value="add">Add</option>
						<option value="update">Update</option>
					</select>

					{getSelectedUpdatePart()}
				</div>
			</div>
		</div>
	);
};

export default UpdateLabContactPerson;

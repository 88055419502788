import React, { useContext, useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";
import LandingHeaderPart from "../components/LandingHeaderPart";
import LandingMission from "../components/LandingMission";
import LandingMission2 from "../components/LandingMission2";
import SetCoeLandingVariables from "../components/SetCoeLandingVariables";
import { CoeContext } from "../context/CoeContext";
import "../css/landing.css";
// import Navigation from '../components/Navigation';

const Home = () => {
	const { landingVariables } = useContext(CoeContext);

	return (
		<>
			<SetCoeLandingVariables />
			{landingVariables ? (
				<>
					<LandingHeaderPart />
					<LandingMission />
					<LandingMission2 />
				</>
			) : (
				<h1>Loading... </h1>
			)}
		</>
	);
};

export default Home;

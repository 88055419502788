import React, { useContext, useEffect } from "react";
import { CoeContext } from "../context/CoeContext";

const LandingMission2 = () => {
	const { landingVariables } = useContext(CoeContext);

	return (
		<>
			{landingVariables["mission2"] && (
				<div className="landing-second-mission-c">
					<div className="second-mission-picture-c">
						<img
							src={landingVariables["mission2"][0].imagelink}
							alt=""
							className="second-mission-picture"
						/>
					</div>

					<div className="second-mission-statement-c">
						<h3 className="second-mission-statement-title">
							{landingVariables["mission2"][0].title}
						</h3>
						<div className="second-mission-statement-para-c">
							<p className="second-mission-statement-para">
								{landingVariables["mission2"][0].description}
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LandingMission2;

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CoeApi from "../apis/CoeApi";
import SetFacultyContactList from "./SetFacultyContactList";
import "../css/updateSquareStyle.css";
import { CoeContext } from "../context/CoeContext";
const FacultyContactListUpdate = () => {
	const { facultyContactList } = useContext(CoeContext);

	let navigate = useNavigate();

	const handleFacultyContactClick = (id) => {
		navigate(`/update/usercoeresearchpasswordeureka99/facultycontact/${id}`);
	};

	const deleteData = async (e, id) => {
		e.stopPropagation();

		try {
			const response = await CoeApi.delete(`/facultycontact/${id}`);

			if (response.status === 204) {
				navigate(`/test`);
				navigate(`/update/usercoeresearchpasswordeureka99/facultycontact`);
			} else {
				alert(
					"Failed! Values not entered like they are supposed to. Please try again or contact your web developer for assistance."
				);
			}
		} catch (err) {
			console.error(err.message);
		}
	};
	return (
		<>
			<SetFacultyContactList />

			<div className="squarediv-c">
				{facultyContactList &&
					facultyContactList.map((eachFacultyContactList) => {
						return (
							<div
								key={eachFacultyContactList.id}
								className="squarediv"
								onClick={() =>
									handleFacultyContactClick(eachFacultyContactList.id)
								}
							>
								<h4 className="squaretitle">{eachFacultyContactList.name}</h4>
								<h4 style={{ color: "brown" }}>
									{eachFacultyContactList.grouping}
								</h4>
								{eachFacultyContactList.imagelink !== "" && (
									<div className="news-pictures-c">
										<img
											src={eachFacultyContactList.imagelink}
											alt=""
											className="squarepicture"
										/>
									</div>
								)}

								<button
									className=""
									onClick={(e) => deleteData(e, eachFacultyContactList.id)}
								>
									Delete
								</button>
							</div>
						);
					})}
			</div>
		</>
	);
};

export default FacultyContactListUpdate;

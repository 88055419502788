import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";

const LabPublications = (props) => {
	const [LabPublications, setLabPublications] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(
					`/labs/${props.labinitial}/publications`
				);
				setLabPublications(response.data.LabpublicationsRows);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return (
		<div className="lab-publications-c">
			{LabPublications &&
				Object.keys(LabPublications).map((key, i) => {
					return (
						<div key={i}>
							<h2 className="lab-publications-title">
								{" "}
								{key === "" ? "Publications" : key}
							</h2>

							<div className="slide-div">
								<div className="all-slides-c">
									{LabPublications[key].map((eachPublication) => {
										return (
											<div key={eachPublication.id} className="each-slide-c">
												<div className="each-slide active">
													<div>
														<p className="each-publication-title ">
															{eachPublication.title}
														</p>
														<p className="authors-part ">
															<span className="author-title">Authors: </span>
															{eachPublication.authors}
														</p>
														{eachPublication.detaillink && (
															<a href={eachPublication.detaillink}>
																<p className="link-part">
																	{" "}
																	<span className="view-title">View</span>
																</p>
															</a>
														)}
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default LabPublications;

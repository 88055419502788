import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";
import { useNavigate } from "react-router-dom";
import "../css/labsNavigation.css";
import "../css/loading.css";
import UpdateSection from "../components/UpdateSection";

const UpdateLabs = () => {
	const [labList, setLabList] = useState();
	let navigate = useNavigate();

	const [updatelabList, setupdatelabList] = useState([]);

	const [updatelabname, setupdatelabname] = useState("");
	const [updatelabinitial, setupdatelabinitial] = useState("");
	const [updatelabintro, setupdatelabintro] = useState("");
	const [updateLonginfo, setupdateLonginfo] = useState("");
	const [updateEmail, setupdateEmail] = useState("");

	const [updatelabCoverPicUrl, setupdatelabCoverPicUrl] = useState("");
	const [dropdownmenuSelection, setdropdownmenuSelection] = useState("add");

	const [updateLabinitialGrouping, setupdateLabinitialGrouping] = useState(
		() => {}
	);

	const addLab = async () => {
		try {
			const response = await CoeApi.post(`/labs`, {
				labname: updatelabname,
				labinitial: updatelabinitial.toUpperCase(),
				labshortintro: updatelabintro,
				lablongintro: updateLonginfo,
				labcoverimagelink: updatelabCoverPicUrl,
				labcontactpersonemail: updateEmail.toLowerCase(),
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
				clearEverything();
			} else if (response.status === 204) {
				alert(
					"Failed! Values not entered like they are supposed to. Please try again or contact your web developer for assistance."
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const updateLab = async () => {
		try {
			const response = await CoeApi.put(`/labs/${updatelabinitial}`, {
				labname: updatelabname,
				labshortintro: updatelabintro,
				lablongintro: updateLonginfo,
				labcoverimagelink: updatelabCoverPicUrl,
				labcontactpersonemail: updateEmail.toLowerCase(),
				labinitial: updatelabinitial.toUpperCase(),
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alert("success");
			} else if (response.status === 204) {
				alert(
					"Failed! Values not entered like they are supposed to. Please try again or contact your web developer for assistance."
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const clearEverything = () => {
		setupdatelabname("");
		setupdatelabinitial("");
		setupdatelabintro("");
		setupdatelabCoverPicUrl("");
		setupdateLonginfo("");
		setupdateEmail("");
	};

	useEffect(() => {
		if (
			updateLabinitialGrouping &&
			updateLabinitialGrouping[updatelabinitial]
		) {
			setupdatelabname(updateLabinitialGrouping[updatelabinitial][0].labname);
			setupdatelabintro(
				updateLabinitialGrouping[updatelabinitial][0].labshortintro
			);
			setupdatelabCoverPicUrl(
				updateLabinitialGrouping[updatelabinitial][0].labcoverimagelink
			);
			setupdateLonginfo(
				updateLabinitialGrouping[updatelabinitial][0].lablongintro
			);
			setupdateEmail(
				updateLabinitialGrouping[updatelabinitial][0].labcontactpersonemail
			);
		}
		return () => {};
	}, [updatelabinitial]);

	useEffect(() => {
		if (
			updateLabinitialGrouping &&
			updateLabinitialGrouping[updatelabinitial]
		) {
			clearEverything();
		}
		return () => {};
	}, [dropdownmenuSelection]);

	useEffect(() => {
		const fetchLabListing = async () => {
			try {
				const result = await CoeApi.get("/labs/");
				setLabList(result.data.LabListingRows);
				setupdateLabinitialGrouping(result.data.LabInitialsGrouping);
				setupdatelabList([]);
				for (let id in result.data.LabListingRows) {
					setupdatelabList((updatelabList) => [
						...updatelabList,
						result.data.LabListingRows[id].labinitial,
					]);
				}
			} catch (err) {}
		};
		fetchLabListing();
	}, []);

	const getSelectedUpdatePart = () => {
		if (dropdownmenuSelection === "add") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Add",

							sectionTitle: "Lab Name",
							sectionTitleValue: updatelabname,
							setSectionTitleValue: setupdatelabname,
							sectionTitlePlaceholder: labList && labList[0].labname,

							sectionDescriptionTitle: "lab description (2-3 sentences)",
							sectionDescriptionValue: updatelabintro,
							setSectionDescriptionValue: setupdatelabintro,
							sectionDescriptionPlaceholder: labList && labList[0].labintro,

							// handle it
							longinfoTitle: "Detailed Information (1 paragraph)",
							longinfoValue: updateLonginfo,
							setlonginfoValue: setupdateLonginfo,

							textInputSecondTitle: "Contact person Email",
							textInputSecondValue: updateEmail,
							settextInputSecondValue: setupdateEmail,
							// handle

							sectionMediaTitle: "Choose/Change Lab's Cover Picture",
							sectionMediaValue: updatelabCoverPicUrl,
							setSectionMediaLink: setupdatelabCoverPicUrl,

							labinitialTitle: "Initials of the lab",
							labinitialValue: updatelabinitial,
							setlabinitialValue: setupdatelabinitial,
							labinitialPlaceholder: labList && labList[0].labinitial,
						}}
					/>
					<div className="button-c">
						<button type="submit" className="btn btn-primary" onClick={addLab}>
							Save
						</button>
					</div>
				</>
			);
		} else if (dropdownmenuSelection === "update") {
			return (
				<>
					<UpdateSection
						props={{
							method: "Update",

							distinguisher: "Your Lab Initials*",
							distinguisherlist: updatelabList,
							distinguisherValue: updatelabinitial,
							setdistinguisherValue: setupdatelabinitial,

							sectionTitle: "Lab Name",
							sectionTitleValue: updatelabname,
							setSectionTitleValue: setupdatelabname,

							sectionDescriptionTitle: "lab description (2-3 sentences)",
							sectionDescriptionValue: updatelabintro,
							setSectionDescriptionValue: setupdatelabintro,

							longinfoTitle: "Detailed Information (1 paragraph)",
							longinfoValue: updateLonginfo,
							setlonginfoValue: setupdateLonginfo,

							email: "Contact person Email",
							emailValue: updateEmail,
							setemailValue: setupdateEmail,

							sectionMediaTitle: "Choose/Change Lab's Cover Picture",
							sectionMediaValue: updatelabCoverPicUrl,
							setSectionMediaLink: setupdatelabCoverPicUrl,
						}}
					/>
					<div className="button-c">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={updateLab}
						>
							Update
						</button>
					</div>
				</>
			);
		}
	};

	const handleLabSelect = (labinitial) => {
		navigate(`/update/usercoeresearchpasswordeureka99/labs/${labinitial}`);
	};
	return (
		<div className="labs-page-main-c update">
			{labList ? (
				<div className="labs-containers-c">
					<div className="update-part">
						<h1>Labs Intro Section</h1>
						<label htmlFor="landingMotto">What would you like to do?</label>

						<select
							id="rating"
							className="custom-select"
							value={dropdownmenuSelection}
							onChange={(e) => setdropdownmenuSelection(e.target.value)}
						>
							<option value="add">Add</option>
							<option value="update">Update</option>
						</select>

						{getSelectedUpdatePart()}
					</div>
					{labList &&
						labList.map((lab) => {
							return (
								<div key={lab.labinitial}>
									<div className="labs-c">
										<div className="each-labs-c">
											<div
												className="lab-details-and-image-c"
												onClick={() => handleLabSelect(lab.labinitial)}
											>
												<img src={lab.labcoverimagelink} alt="" />
												<div className="lab-details-c">
													<h2>
														{lab.labname} ({lab.labinitial})
													</h2>
													<p>{lab.labintro}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
				</div>
			) : (
				<div className="labs-containers-c">
					<h1 className="loading-style">Loading Labs List ...</h1>
				</div>
			)}
		</div>
	);
};

export default UpdateLabs;

import React from "react";
import { useParams } from "react-router-dom";
import LabContactPerson from "../components/LabContactPerson";
import LabHeader from "../components/LabHeader";
import LabMembers from "../components/LabMembers";
import "../css/labs.css";
import "../css/card.css";
import "../css/slides.css";
import LabProjects from "../components/LabProjects";
import LabPublications from "../components/LabPublications";
import LabTitleAndImages from "../components/LabTitleAndImages";
import LabTitleAndVideos from "../components/LabTitleAndVideos";
import LabEquipments from "../components/LabEquipments";
import UpdateLabHeader from "./UpdateLabHeader";
import UpdateLabContactPerson from "./UpdateLabContactPerson";
import { useNavigate } from "react-router-dom";
import UpdateLabsLandingComponent from "./UpdateLabsLandingComponent";
import UpdateLabTitleAndImages from "./UpdateLabTitleAndImages";
import UpdateLabEquipments from "./UpdateLabEquipments";
import UpdateLabTitleAndVideos from "./UpdateLabTitleAndVideos";
import "../css/styleUpdateSection.css";

const UpdateLabpage = () => {
	var { labinitial } = useParams();
	let navigate = useNavigate();

	labinitial = labinitial.toUpperCase();

	const goToUpdateStudents = () => {
		navigate(
			`/add/userlabmemberpasswordcoemember234/labs/${labinitial}/student`
		);
	};
	const goToUpdateFaculty = () => {
		navigate(
			`/add/userlabmemberpasswordcoemember234/labs/${labinitial}/faculty`
		);
	};
	const goToUpdateProjects = () => {
		navigate(
			`/add/userlabmemberpasswordcoemember234/labs/${labinitial}/projects`
		);
	};
	const goToUpdatePublications = () => {
		navigate(
			`/add/userlabmemberpasswordcoemember234/labs/${labinitial}/publications`
		);
	};
	const goToUpdateEquipments = () => {
		navigate(
			`/add/userlabmemberpasswordcoemember234/labs/${labinitial}/labequipments`
		);
	};

	return (
		<>
			<UpdateLabHeader labinitial={labinitial} />

			<LabContactPerson labinitial={labinitial} />
			<UpdateLabContactPerson labinitial={labinitial} />

			<LabMembers labinitial={labinitial} />
			<div className="button-c">
				<button onClick={goToUpdateStudents}>Add/Update Student Members of the Lab</button>
			</div>
			<div className="button-c">
				<button onClick={goToUpdateFaculty}>Add/Update Faculty Members of the Lab</button>
			</div>

			<LabProjects labinitial={labinitial} />
			<div className="button-c">
				<button onClick={goToUpdateProjects}>Add/Update Projects</button>
			</div>

			<LabPublications labinitial={labinitial} />
			<div className="button-c">
				<button onClick={goToUpdatePublications}>
					Add/Update Publications
				</button>
			</div>

			<UpdateLabTitleAndImages labinitial={labinitial} />
			<UpdateLabTitleAndVideos labinitial={labinitial} />

			<UpdateLabEquipments labinitial={labinitial} />
			{/* <LabEquipments labinitial={labinitial}/>
			<div className="button-c"><button  onClick={goToUpdateEquipments}>
				Add/Update Lab Equipments
			</button></div> */}
		</>
	);
};

export default UpdateLabpage;

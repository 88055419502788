import React, { useEffect, useState } from "react";
import CoeApi from "../apis/CoeApi";

const LabEquipments = (props) => {
	const [LabEquipments, setLabEquipments] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await CoeApi.get(
					`/labs/${props.labinitial}/labequipments`
				);
				setLabEquipments(response.data.LabEquipmentsRows);
				// console.log(response.data.LabEquipmentsRows);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, []);

	return LabEquipments && LabEquipments.length > 0 ? (
		<div className="lab-equipments-c">
			<div className="lab-equipments-title-c">
				<h2 className="lab-equipments-title">
					{LabEquipments.length > 0 && "Equipments"}
				</h2>
			</div>

			{LabEquipments &&
				LabEquipments.map((eachEquipment) => {
					return (
						<h3 className="lab-equipment-name" key={eachEquipment.id}>
							{eachEquipment.title}
						</h3>
					);
				})}
		</div>
	) : (
		<div style={{ height: "0px" }}></div>
	);
};

export default LabEquipments;

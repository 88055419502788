import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCtEUqZAIu8iuK_vMVmfW6P-GFGZEIolpI",
    authDomain: "fir-image-a94c7.firebaseapp.com",
    projectId: "fir-image-a94c7",
    storageBucket: "fir-image-a94c7.appspot.com",
    messagingSenderId: "164184960711",
    appId: "1:164184960711:web:f5b66b89aa8ac7613298e2",
    measurementId: "G-1WGG6LHY0F"
  };

  export const app = initializeApp(firebaseConfig)
  export const storage = getStorage(app)
import React from "react";
import { useParams } from "react-router-dom";
import LabContactPerson from "../components/LabContactPerson";
import LabHeader from "../components/LabHeader";
import LabMembers from "../components/LabMembers";
import "../css/labs.css";
import "../css/card.css";
import "../css/slides.css";
import LabProjects from "../components/LabProjects";
import LabPublications from "../components/LabPublications";
import LabTitleAndImages from "../components/LabTitleAndImages";
import LabTitleAndVideos from "../components/LabTitleAndVideos";
import LabEquipments from "../components/LabEquipments";

const Labpage = () => {

	var { labinitial } = useParams();
	labinitial = labinitial.toUpperCase();

	return <>
		<LabHeader labinitial={labinitial}/>
		<LabContactPerson labinitial={labinitial}/>
		<LabMembers labinitial={labinitial}/>
		<LabTitleAndImages labinitial={labinitial}/>
		<LabTitleAndVideos labinitial={labinitial}/>
		<LabProjects labinitial={labinitial}/>
		<LabPublications labinitial={labinitial}/>
		<LabEquipments labinitial={labinitial}/>
	</>;
};

export default Labpage;
